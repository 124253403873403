<template>
  <div class="graphs">
    <h1>
      Project work
      <ProjectSelector v-model="selectedProject" />
    </h1>
    <BarChart
      :labels="labels"
      :series="combined_series"
      color="red"
      title="Combined"
    />
    <div class="buttons">
      <button
        :class="{ button: true, active: showDaysHours }"
        @click="showDaysHours = !showDaysHours"
      >
        <span v-if="!showDaysHours">Show</span><span v-else>Hide</span> days and
        hours
      </button>

      <form @submit.prevent="uploadEntriesFile">
        <div class="fileUpload">
          <label>
            <input
              id="file"
              ref="file"
              type="file"
              @change="handleFileUpload()"
            />
          </label>
          <button :class="{ button: true }" type="submit">
            Upload Entries
          </button>
        </div>
      </form>
    </div>

    <div v-if="uploadComplete">
      <h3>Upload results</h3>
      <pre>{{ uploadResponse }}</pre>
    </div>

    <div :style="{ display: showDaysHours ? 'block' : 'none' }">
      <BarChart
        :labels="labels"
        :series="days_series"
        color="green"
        title="Days"
      />
      <BarChart
        :labels="labels"
        :series="hours_series"
        color="blue"
        title="Hours"
      />
    </div>

    <div class="table">
      <h2>Stats for the current month</h2>
      <table>
        <thead>
          <tr>
            <th>Project</th>
            <th>Combined days</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(s, index) in monthProjectStats" :key="index">
            <td>{{ s['project__name'] }}</td>
            <td>{{ s['combined_days'] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import { log } from '../utils.js'
import BarChart from './BarChart.vue'
import ProjectSelector from './ProjectSelector.vue'

export default {
  components: {
    BarChart,
    ProjectSelector,
  },
  filters: {
    removeDuplicates: function (value) {
      const result = [...new Set(value)]
      return result
    },
  },
  props: [],

  data() {
    return {
      values: {
        days: [],
        hours: [],
      },
      file: '',
      monthProjectStats: [],
      showDaysHours: false,
      selectedProject: null,
      uploadComplete: false,
      uploadResponse: {},
    }
  },

  computed: {
    labels: function () {
      return _.map(this.values.hours, (d) => d[0])
    },
    hours_series: function () {
      return [_.map(this.values.hours, (d) => d[1])]
    },
    days_series: function () {
      return [_.map(this.values.days, (d) => d[1])]
    },
    combined_series: function () {
      const combined = _.map(this.values.days, (d) => d[1])
      _.each(this.values.hours, (h, i) => {
        combined[i] += h[1] / 7.5
      })
      return [combined]
    },
  },

  watch: {
    selectedProject() {
      this.fetchProjects()
    },
  },

  mounted() {
    log('TimeEntryGraphs.mounted()')
    this.fetchProjects()
  },

  methods: {
    fetchProjects() {
      fetch(`/api/v1/time-entries/monthly?pid=${this.selectedProject || ''}`, {
        credentials: 'same-origin',
      })
        .then((response) =>
          response.json().then((data) => (this.values = data))
        )
        .then(() => {
          fetch('/api/v1/time-entries/project_sums_for_month', {
            credentials: 'same-origin',
          }).then((response) =>
            response.json().then((data) => (this.monthProjectStats = data))
          )
        })
    },

    handleFileUpload() {
      console.log('file uploaded')
      this.file = this.$refs.file.files[0]
    },

    getCookie(name) {
      /// Retrieve cookie function
      const match = document.cookie.match(new RegExp(name + '=([^;]+)'))
      if (match) return match[1]
    },

    async uploadEntriesFile() {
      const csrfToken = this.getCookie('csrftoken')
      const formData = new FormData()

      if (this.file) {
        formData.append('file', this.file)
      } else {
        console.log('No file selected')
      }

      console.log('form data  => ', formData.get('file'))
      const payload = {
        credentials: 'same-origin',
        headers: {
          'X-CSRFToken': csrfToken,
        },
        body: formData,
      }

      console.log(payload)

      const url = '/api/v1/employees/external/'
      payload.method = 'POST'

      fetch(url, payload)
        .then((response) => {
          // log("Success", response);
          // log("Upload response", response);
          if (response.status === 200) {
            this.uploadComplete = true
            return response.json()
          }
        })
        .then((data) => (this.uploadResponse = data))
        .catch((error) => console.error('Error uploading file: ', error))
    },
  },
}
</script>
