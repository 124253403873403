<template>
  <div class="bar-chart">
    <h2 v-if="title">
      {{ title }}
    </h2>
    <div
      :style="{
        height: height || '30vh',
        width: '100%',
        position: 'relative',
      }"
    >
      <canvas ref="chart" />
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  props: {
    labels: {
      type: Array,
      default: () => [],
    },

    series: {
      type: Array,
      default: () => [],
    },

    title: {
      type: String,
      default: '',
    },

    color: {
      type: [Array, String],
      default: () => (this.grouped ? '' : []),
    },

    grouped: {
      type: Boolean,
      default: false,
    },

    titles: {
      type: Array,
      default: () => [],
    },

    height: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      chart: null,
    }
  },

  computed: {
    dataset() {
      if (!this.grouped) {
        return [
          {
            label: this.title,
            data: this.series[0],
            backgroundColor: this.color,
          },
        ]
      } else {
        return this.series.map((d, i) => ({
          label: this.titles[i],
          data: d,
          backgroundColor: this.color[i],
        }))
      }
    },
  },

  watch: {
    series(series) {
      this.update()
    },
  },

  methods: {
    update() {
      if (this.chart) {
        this.chart.destroy()
      }
      this.chart = new Chart(this.$refs.chart, {
        type: 'bar',
        data: {
          labels: this.labels,
          datasets: this.dataset,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      })
    },
  },
}
</script>

<style lang="scss"></style>
