<template>
  <div class="tag">
    <span class="small-text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
.tag {
  padding: 2px 5px;
  background-color: #828282;
  font-size: 0.7em;
  border-radius: 3px;
  color: #f2f2f2;
  margin: 2px 0;
}
</style>
