<template>
  <div class="calendar-event-list">
    <h2>Holidays</h2>

    <table
      class="calendar-event-list-table"
      :class="{ 'limit-height': limitHeight }"
    >
      <tbody>
        <tr v-for="event in eventList" :key="event.id">
          <td>
            {{ event.start | formatDate }} –
            {{ event.end | formatDate }}
          </td>
          <td>{{ event.description }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'CalendarEventList',

  filters: {
    formatDate(value) {
      return moment(value).format('DD.MM')
    },
  },

  props: {
    eventList: {
      type: Array,
      default: () => [],
    },

    limitHeight: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/variables';

$foreground-color: $grey;
$row-background-color: $white;
$row-background-color-alt: $light-grey;

.calendar-event-list {
  h2 {
    margin-top: 0;
  }

  .calendar-event-list-table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: hidden;
    overflow-y: scroll;

    &,
    & > tbody,
    & > tbody > tr {
      display: block;
    }

    &.limit-height {
      max-height: 25em;
    }

    tr {
      background-color: $row-background-color;

      &:nth-child(odd) {
        background-color: $row-background-color-alt;
      }

      td {
        padding: 1em 0.5em;
        color: $foreground-color;
      }
    }
  }
}
</style>
