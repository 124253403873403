<template>
  <table v-if="kudos.length > 0" class="kudos-table table">
    <thead>
      <tr>
        <th>DATE</th>
        <th>{{ given ? 'TO' : received ? 'FROM' : '?' }}</th>
        <th>MESSAGE</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(kudo, kudo_index) in kudos">
        <tr
          :key="kudo.id"
          :class="{
            row: true,
            even: !!!(kudo_index % 2),
            odd: !!(kudo_index % 2),
          }"
          @click="toggleRow(kudo.id)"
        >
          <td>{{ date_formatter(kudo.created_at) }}</td>
          <td>
            <div v-if="received" class="from">
              <img :src="kudo.giver.photo" :alt="kudo.giver.name" />
              <p>{{ kudo.giver.name }}</p>
            </div>
            <div
              v-for="(receiver, receiver_index) in kudo.receivers"
              v-else-if="given"
              :key="receiver_index"
              class="from"
            >
              <img :src="receiver.photo" :alt="receiver.name" />
              <p>{{ receiver.name }}</p>
            </div>
          </td>
          <td>{{ kudo.description }}</td>
        </tr>
        <tr
          v-if="expanded.includes(kudo.id) && kudo.skills.length > 0"
          :key="kudo.id"
          :class="{
            row: true,
            even: !!!(kudo_index % 2),
            odd: !!(kudo_index % 2),
          }"
          @click="toggleRow(kudo.id)"
        >
          <td class="expanded-row" colspan="3">
            <p class="row-divider">TEAM VALUES 🙌</p>
            <p v-for="(value, j) in kudo.team_values" :key="j">
              🚀 {{ value.text }}
            </p>
          </td>
        </tr>
        <tr
          v-if="expanded.includes(kudo.id) && kudo.skills.length > 0"
          :key="kudo.id"
          :class="{
            row: true,
            even: !!!(kudo_index % 2),
            odd: !!(kudo_index % 2),
          }"
          @click="toggleRow(kudo.id)"
        >
          <td class="expanded-row" colspan="3">
            <p class="row-divider">SKILLS 🧑‍💻</p>
            <p v-for="(skill, k) in kudo.skills" :key="k">
              🌟 {{ skill.text }}
            </p>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import moment from 'moment'

export default {
  name: 'KudosTable',
  props: {
    kudos: {
      type: Array,
      default: () => [],
    },
    given: {
      type: Boolean,
      default: false,
    },
    received: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      responsive: false,
      expanded: [],
    }
  },
  methods: {
    date_formatter(date) {
      return moment(date).format('Do MMM, YYYY')
    },
    toggleRow(index) {
      const i = this.expanded.indexOf(index)
      if (i > -1) {
        this.expanded.splice(i, 1)
      } else {
        this.expanded.push(index)
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/table';

.kudos-table {
  width: 100%;
  padding: 20px;
  border-collapse: collapse;
  margin: 0;

  .from {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: left;

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 50%;
    }
  }

  .expanded-row {
    padding: 1.5em 0;

    .row-divider {
      margin-bottom: 3px solid red;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  th:nth-child(1) {
    text-align: left;
    width: 20%;
  }

  td:nth-child(1) {
    text-align: left;
  }

  // this is the author
  th:nth-child(2),
  td:nth-child(2) {
    text-align: left;
    padding: 0 10px;
    width: 20%;
  }

  th:nth-child(3) {
    text-align: center;
    width: 60%;
  }

  td:nth-child(3) {
    text-align: left;
  }
}
</style>
