<template>
  <transition name="modal">
    <div class="edit-time-entry-modal">
      <div class="content-wrapper">
        <div class="content">
          <TimeEntryForm
            :time-entry="timeEntry"
            @dismiss="$emit('dismiss')"
            @submit="editTimeEntry"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import TimeEntryForm from '@/components/employee-ng/TimeEntryForm'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'EditTimeEntry',

  components: {
    TimeEntryForm,
  },

  props: {
    timeEntry: {
      type: Object,
      default: null,
    },
  },

  emits: ['dismiss'],

  data() {
    return {
      currentMonth: moment().format('YYYY-M'),
    }
  },

  methods: {
    ...mapActions(['updateEntry', 'fetchEntries']),

    async editTimeEntry(entry) {
      console.log('Updating time entry', entry)
      try {
        await this.updateEntry(entry)
        await this.fetchEntries(this.currentMonth, null)
      } catch (error) {
        console.error(`Updating the entry ${entry.id} went bananas: ${error}`)
      } finally {
        this.$emit('dismiss')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-time-entry-modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000e5;
  transition: opacity 0.3s ease;
  display: table;

  .content-wrapper {
    display: table-cell;
    vertical-align: middle;

    .content {
      box-sizing: border-box;
      max-width: 95%;
      margin: auto;
      padding: 3rem;
      background-color: white;
      border-radius: 0.5rem;

      @media screen and (min-width: 480px) {
        max-width: 80%;
      }
    }
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.1);
}
</style>
