<template>
  <div class="main">
    <div class="pie">
      <PieChart
        :colors="chartColors"
        :series="chartDataSeries"
        :tooltip-labels="chartTooltipLabels"
        height="100px"
      />
    </div>

    <div class="inside-text">
      <div class="inline-switch">
        <template v-for="(tab, index) in tabs">
          <div
            :key="`tab-${index}`"
            class="inline-switch-element"
            :class="{ active: activeTab === tab }"
            @click="setActiveTab(tab)"
          >
            {{ tab.caption }}
          </div>

          <div v-if="1 + index < tabs.length" :key="`middot-${index}`">
            &middot;
          </div>
        </template>
      </div>

      <p>
        You've worked on {{ projectCount }} different projects, biggest one
        being <strong>{{ biggestProject }}</strong> with {{ mostDays }} days.
      </p>
    </div>

    <div class="contribution-container">
      <div class="box-container">
        <span class="big-number">
          {{ totalDays }}
        </span>
        <span class="box-label">days</span>
      </div>

      <div class="box-container">
        <span class="big-number">
          {{ totalReportingPercentage }}<span class="small-text">%</span>
        </span>
        <span class="box-label">reported</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-business-days'
import { mapActions, mapState } from 'vuex'

import PieChart from './PieChart.vue'

const LOADING_PLACEHOLDER = '...'

export default {
  name: 'TimeEntrySummary',

  components: {
    PieChart,
  },

  data() {
    return {
      activeTab: null,
      isLoading: false,
    }
  },

  computed: {
    ...mapState({
      employeeContributions: (state) => state.ngDashboard.employeeContributions,
    }),

    tabs() {
      return [
        {
          caption: 'Current Month',
          from: moment().startOf('month'),
          thru: moment(),
        },
        {
          caption: 'Past 30 Days',
          from: moment().subtract(30, 'days'),
          thru: moment(),
        },
      ]
    },

    /* Chart data */

    chartColors() {
      return this.employeeContributions.map((d) => d.color)
    },

    chartDataSeries() {
      return this.employeeContributions.map((d) => d.days)
    },

    chartTooltipLabels() {
      return this.employeeContributions.map((a) => a.name)
    },

    /* Stats */

    biggestProject() {
      if (this.isLoading || !this.activeTab) {
        return LOADING_PLACEHOLDER
      }

      if (this.employeeContributions.length > 0) {
        return this.employeeContributions[0].name
      }

      return null
    },

    mostDays() {
      if (this.isLoading || !this.activeTab) {
        return LOADING_PLACEHOLDER
      }

      if (this.employeeContributions.length > 0) {
        return this.employeeContributions[0].days.toFixed(2)
      }

      return null
    },

    projectCount() {
      return this.isLoading || !this.activeTab
        ? LOADING_PLACEHOLDER
        : this.chartDataSeries.length
    },

    totalDays() {
      return this.isLoading || !this.activeTab
        ? LOADING_PLACEHOLDER
        : this.chartDataSeries.reduce((x, total) => x + total, 0).toFixed(1)
    },

    totalReportingPercentage() {
      return this.isLoading || !this.activeTab
        ? LOADING_PLACEHOLDER
        : (
            (100 * this.totalDays) /
            this.activeTab.thru.businessDiff(this.activeTab.from)
          ).toFixed(0)
    },
  },

  watch: {
    async activeTab(newTab, currentTab) {
      if (!newTab || newTab === currentTab) {
        return
      }

      this.isLoading = true

      try {
        await this.fetchEmployeeContributions(newTab.from)
      } catch (error) {
        console.error(`Failed fetching employee contributions: ${error}`)
      }

      this.isLoading = false
    },
  },

  mounted() {
    this.setActiveTab(this.tabs.at(0))
  },

  methods: {
    ...mapActions(['fetchEmployeeContributions']),

    setActiveTab(tab) {
      this.activeTab = tab
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/variables';

$inline-switch-active-element-font-weight: 600;
$inline-switch-element-font-weight: 200;
$inline-switch-element-opacity: 0.5;

.main {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 20px;

  .pie {
    width: 100px;
    height: 100px;
  }

  .inside-text {
    flex: 1 0 280px;

    h3 {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25em;
      text-transform: uppercase;
    }

    .inline-switch {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.5em;
      font-size: 1em;
      text-transform: uppercase;
      user-select: none;

      .inline-switch-element {
        font-weight: $inline-switch-element-font-weight;
        opacity: $inline-switch-element-opacity;
        transition: all 200ms ease-in-out;

        &:hover,
        &.active {
          opacity: 1;
        }

        &.active {
          font-weight: $inline-switch-active-element-font-weight;
        }

        &:not(.active) {
          border-bottom: 1px solid;
          cursor: pointer;
        }
      }
    }
  }

  .contribution-container {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .box-container {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      min-width: 130px;
      aspect-ratio: 1 / 1;
      background: #fafafa;
      border: 1px solid #eee;
      box-sizing: border-box;
      padding: 5px;

      .big-number,
      .small-text {
        font-style: normal;
        line-height: 1.25em;
      }

      .big-number,
      .small-text,
      .box-label {
        text-align: center;
      }

      .big-number {
        font-size: 48px;
        font-weight: 700;
      }

      .small-text {
        font-size: 20px;
        font-weight: 400;
        padding-left: 0.25em;
      }
    }
  }
}
</style>
