<template>
  <div>
    <table v-if="!loading && entries.length > 0" class="entries-table table">
      <thead>
        <tr>
          <th class="date">Date</th>
          <th class="total-amount">Combined</th>
          <th class="amount">Amount</th>
          <th class="project">Project</th>
          <th class="tags">Tags</th>
          <th v-if="!responsive" class="comment">Comment</th>
          <th class="actions" style="width: fit-content">
            <span class="actions-header">
              Actions
              <font-awesome-icon
                v-if="responsive"
                icon="angle-double-down"
                :title="allExpanded ? 'Collapse all' : 'Expand all'"
                :class="{ icon: true, open: allExpanded }"
                @click="toggleAll"
              />
            </span>
          </th>
        </tr>
      </thead>
      <template v-for="(week, i) in entryWeeksSorted">
        <tr :key="`${week}-${i}`" class="week-header">
          <td>
            <p class="week-number">Week {{ week }}</p>
          </td>
          <td>
            <p class="week-combined">{{ combinedWeekFormatter(week) }}</p>
          </td>
          <td :colspan="responsive ? 4 : 5"></td>
        </tr>

        <tbody
          v-for="(date, j) in entryDatesSorted[week]"
          :key="`${date}+${j}`"
        >
          <template v-for="(entry, k) in entriesGroupedByWeek[week][date]">
            <tr :key="entry.id" @click="toggleRow(entry.id)">
              <td
                v-if="k === 0"
                class="date"
                :rowspan="entriesGroupedByWeek[week][date].length"
              >
                <p>{{ dateFormatter(entry.date) }}</p>
              </td>
              <td
                v-if="k === 0"
                class="total-amount"
                :rowspan="entriesGroupedByWeek[week][date].length"
              >
                <p>{{ tagFormatter(week, date) }}</p>
              </td>
              <td class="amount">
                <p>{{ dayFormatter(entry) }}</p>
              </td>
              <td class="project">
                <p>{{ entry.project }}</p>
              </td>
              <td class="tags">
                <TimeEntryTag
                  v-for="tag in entry.tags"
                  :key="tag.id"
                  :text="tag.name"
                />
              </td>
              <td v-if="!responsive" class="comment">
                <p>{{ entry.msg }}</p>
              </td>
              <td class="actions">
                <div class="action-icons">
                  <font-awesome-icon
                    v-if="responsive"
                    :class="{
                      'action-icon': true,
                      open: expanded.includes(entry.id),
                    }"
                    icon="chevron-right"
                  />
                  <font-awesome-icon
                    class="action-icon"
                    icon="edit"
                    @click="editEntry(entry.id)"
                  />
                  <font-awesome-icon
                    class="action-icon"
                    icon="trash-alt"
                    @click="deleteEntry(entry.id)"
                  />
                </div>
              </td>
            </tr>
            <tr
              v-if="responsive && expanded.includes(entry.id)"
              :key="entry.id"
              :class="{ row: true, even: !!!(i % 2), odd: !!(i % 2) }"
              @click="toggleRow(entry.id)"
            >
              <td class="comment-row" colspan="4">
                <p class="comment-divider">COMMENT</p>
                {{ entry.msg }}
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </table>
    <div v-else class="skeleton" style="margin: 0">
      <VclTable v-if="loading" :rows="4" :columns="responsive ? 5 : 6" />
      <p v-else-if="entries.length === 0" class="no-entries">
        😿 No time entries this month 😿
      </p>
    </div>
  </div>
</template>

<script>
import TimeEntryTag from '@/components/employee-ng/TimeEntryTag'
import { combinedDaysForEntries } from '@/utils'
import _ from 'lodash'
import moment from 'moment'
import { VclTable } from 'vue-content-loading'

export default {
  name: 'TimeEntriesTable',

  components: {
    VclTable,
    TimeEntryTag,
  },

  props: {
    entries: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      expanded: [],
      screenWidth: innerWidth,
    }
  },
  computed: {
    allExpanded() {
      return this.expanded.length === this.entries.length
    },

    responsive() {
      return this.screenWidth < 1080
    },

    entryWeeksSorted() {
      return Object.keys(this.entryDatesSorted).sort((a, b) => b - a)
    },

    entryDatesSorted() {
      return _(this.entries)
        .sortBy('date')
        .map('date')
        .uniq()
        .reverse()
        .groupBy((d) => moment(d).isoWeek())
        .value()
    },

    entriesGroupedByWeek() {
      const groupedByDate = _(this.entries)
        .sortBy((e) => e.date)
        .groupBy('date')
        .value()

      return Object.keys(groupedByDate).reduce((acc, key) => {
        const week = moment(key).isoWeek().toString()
        if (week in acc) {
          acc[week][key] = groupedByDate[key]
        } else {
          acc[week] = { [key]: groupedByDate[key] }
        }
        return acc
      }, {})
    },
  },

  created() {
    window.addEventListener('resize', this.resizeEventHandler)
  },

  destroyed() {
    window.removeEventListener('resize', this.resizeEventHandler)
  },

  methods: {
    tagFormatter(week, date) {
      const days = combinedDaysForEntries(this.entriesGroupedByWeek[week][date])
        .toFixed(2)
        .toString()
        .replace('.00', '')

      return `${days} day${days !== '1' ? 's' : ''}`
    },

    dateFormatter(date) {
      return moment(date).format('ddd, DD MMM. YYYY')
    },

    dayFormatter(entry) {
      let amount = ''
      if (entry.days) {
        const days = entry.days
        switch (days) {
          case 0.25:
            amount = '1/4 day'
            break
          case 0.5:
            amount = '1/2 day'
            break
          case 0.75:
            amount = '3/4 day'
            break
          case 1.0:
            amount = '1 day'
            break
        }
      } else {
        const hours = entry.hours
        if (hours <= 1) {
          amount = `${hours} hour`
        } else {
          amount = `${hours} hours`
        }
      }
      return amount
    },

    combinedWeekFormatter(week) {
      const worked = Object.values(this.entriesGroupedByWeek[week])
        .reduce((prv, cur) => prv + combinedDaysForEntries(cur), 0)
        .toFixed(2)
        .toString()
        .replace('.00', '')

      return `${worked} day${worked !== '1' ? 's' : ''}`
    },

    toggleRow(index) {
      const i = this.expanded.indexOf(index)
      if (i > -1) {
        this.expanded.splice(i, 1)
      } else {
        this.expanded.push(index)
      }
    },

    toggleAll() {
      if (this.allExpanded) {
        // collapse all
        this.expanded = []
      } else {
        this.expanded = this.entries.map((entry) => entry.id)
      }
    },

    resizeEventHandler(e) {
      if (e.type === 'resize') {
        this.screenWidth = innerWidth
      }
    },

    // Entry actions
    deleteEntry(entryId) {
      if (confirm('Are you sure?')) {
        this.$emit('delete-entry', entryId)
      }
    },

    editEntry(entryId) {
      this.$emit('edit-entry', entryId)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/table';
@import '../../assets/scss/variables';

.entries-table {
  width: 100%;
  padding: 20px;
  border-collapse: collapse;
  margin: 0;

  .actions-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;

    .icon {
      margin-left: 10px;
      transition: all 0.2s;
      font-size: 1.2em;

      &:hover {
        color: var(--employee-color);
        cursor: pointer;
        transition: all 0.2s;
      }

      &.open {
        transform: rotateZ(-90deg);
        transition: all 0.2s;
      }
    }
  }

  tbody {
    &:nth-child(2n) {
      background: $light-grey;
    }

    transition: all 0.1s;

    &:hover {
      background: $lighter-grey;
      transition: all 0.1s;

      td {
        color: white;
      }
    }

    td {
      border: 1px solid $lighter-grey;

      p {
        margin: 0.4rem;
      }
    }
  }

  th {
    text-transform: uppercase;
  }

  tr.week-header {
    text-align: center;
    background: $grey;

    td {
      color: #fafafa;
    }

    .week-combined {
      text-align: center;
    }

    .week-number {
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
    }

    p {
      margin: 5px 0;
    }
  }

  th.amount,
  td.amount,
  th.total-amount,
  td.total-amount {
    text-align: center;
    width: min-content;
  }

  th.date,
  td.date {
    padding-left: 5px;
    width: min-content;
  }

  th.actions,
  td.actions {
    padding-right: 0;
  }

  th.project,
  td.project {
    text-align: center;
  }

  td.actions {
    .action-icons {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;
      align-items: center;

      .action-icon {
        font-size: 1.3em;
        transition: all 0.2s;

        &.open {
          transform: rotateZ(90deg);
          transition: all 0.2s;
        }

        &:hover {
          color: var(--employee-color);
          cursor: pointer;
          transition: all 0.2s;
        }
      }
    }
  }

  .comment-row {
    padding: 1.5em 0;

    .comment-divider {
      margin: 0 0 0.5em;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .client {
    position: relative;
    vertical-align: middle;

    img {
      $min-height: 42px;
      $aspect-ratio: 2.333;

      max-width: $min-height * $aspect-ratio;
      min-height: $min-height;
      aspect-ratio: $aspect-ratio;
      border-radius: 4px;
      background-color: $atmos-violet;
      color: $white;
    }

    .tooltiptext {
      display: none;
      color: $light-grey;
      background: $black;
      text-align: left;
      padding: 2px;
      position: absolute;
      z-index: 1;
      border: 1px solid;
      top: 0;
      margin-left: 5px;
    }

    &:hover {
      .tooltiptext {
        display: block;
      }
    }
  }
}

.skeleton {
  .no-entries {
    margin: 50px auto;
    width: fit-content;
  }
}
</style>
