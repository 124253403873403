<template>
  <div class="bar-chart">
    <h2 v-if="title">
      {{ title }}
    </h2>
    <div
      :style="{
        height: height || '30vh',
        width: '100%',
        position: 'relative',
      }"
      class="graph-area"
    >
      <canvas ref="chart" />
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  props: {
    labels: {
      type: Array,
      default: () => [],
    },

    series: {
      type: Array,
      default: () => [],
    },

    height: {
      type: [String, Number],
      default: null,
    },

    title: {
      type: String,
      default: '',
    },

    colors: {
      type: Array,
      default: () => [],
    },

    tooltipLabels: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      chart: null,
    }
  },
  watch: {
    series(series) {
      this.update()
    },
  },
  methods: {
    update() {
      if (this.chart) {
        this.chart.destroy()
      }
      this.chart = new Chart(this.$refs.chart, {
        type: 'pie',
        data: {
          labels: this.labels,
          ttLabels: this.tooltipLabels,
          datasets: [
            {
              backgroundColor: this.colors,
              data: this.series,
            },
          ],
        },
        options: {
          tooltips: {
            enabled: true,
            callbacks: {
              label: function (tooltipItem, data) {
                return data.ttLabels
                  ? data.ttLabels[tooltipItem.index]
                  : data.labels[tooltipItem.index]
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@media screen and (max-width: 720px) {
  .graph-area {
    height: 50vh;
  }
}
</style>
