<template>
  <div class="upload">
    <h2>Add Public Holidays</h2>
    <div class="input-container">
      <input ref="fileInput" type="file" @change="validateFile" />
      <button class="browse-btn" @click="takeFile">Browse Files</button>
      <span class="file-info">{{ fileMessage }}</span>
    </div>
    <span v-show="error" class="error">{{ error }}<br /></span>
    <span v-show="success" class="success">{{ success }}</span
    ><br />
    <button v-show="file" class="btn" @click="handleSubmit()">Upload</button>
  </div>
</template>

<script>
import { log } from '../utils.js'

export default {
  name: 'PublicEventsUpload',

  data() {
    return {
      file: null,
      error: '',
      success: '',
      fileMessage: 'Upload File',
    }
  },

  methods: {
    takeFile() {
      this.$refs.fileInput.click()
    },
    validateFile() {
      const file = this.$refs.fileInput.files[0]
      if (file) {
        const extension = file.name
          .substr(file.name.lastIndexOf('.') + 1)
          .toLowerCase()
        if (extension !== 'csv') {
          this.error = 'Please enter a .csv file'
          this.file = null
          this.fileMessage = 'Upload File'
          return false
        }
        this.file = file
        this.fileMessage = this.file.name
        this.error = ''
      }
    },

    async handleSubmit() {
      const csrfToken = document.getElementsByName('csrfmiddlewaretoken')[0]
        .value
      const formData = new FormData()
      formData.append('file', this.file)
      fetch('/api/v1/projects/events/load/', {
        credentials: 'same-origin',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': `attachment; filename=${this.file.name}`,
          'X-CSRFToken': csrfToken,
        },
        body: formData,
      })
        .then((response) => {
          log('Success', response)
          if (response.status === 201) {
            this.error = ''
            this.success = 'Public holidays updated successfully'
            location.reload(true)
          } else {
            this.error = 'Some problem with the uploaded file.'
          }
        })
        .catch((error) => console.error('Error saving event: ', error))
    },
  },
}
</script>

<style lang="scss"></style>
