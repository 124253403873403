<template>
  <div class="day-per-day-employees">
    <table style="width: 100%">
      <tr>
        <th v-for="column in columns" :key="column">{{ column }}</th>
      </tr>
      <tr v-for="(row, i) in rows" :key="i">
        <td
          v-for="([value, title, unBillableValue, mcWork], ii) in row"
          :key="ii"
          :title="title"
          :style="getCellStyles(value, unBillableValue, mcWork)"
        >
          <span
            v-if="ii === 0"
            class="name"
            @click="() => (focusOnRow = focusOnRow === null ? i : null)"
            >{{ value }}</span
          >
          <span
            v-if="ii === 1"
            class="sums"
            :title="`billable: ${value.sum}, MC: ${value.mc_sum}, unbillable: ${value.unbillable}`"
            >{{ value.sum }}/{{ value.mc_sum }}/{{ value.unbillable }}</span
          >
          <span
            v-if="ii > 1"
            :class="{
              'has-value': value > 0 || unBillableValue > 0,
            }"
            >{{ value }}</span
          >
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    month: { type: String, default: null, required: true },
  },

  data() {
    return { data: [], focusOnRow: null }
  },

  computed: {
    columns() {
      return ['employee', 'sum'].concat(this.data[1])
    },
    rows() {
      // we get array of arrays [timeentriesforemployees, dates as strings]
      if (this.data[0]) {
        const all = this.data[0].map(([employeeData, entries]) => {
          const filledEntries = this.fillDates(entries)
          const employeeSum = entries.reduce(
            (col, entry) => {
              col.sum += entry.sum
              col.unbillable += entry.unbillable
              col.mc_sum += entry.mc_sum
              return col
            },
            { sum: 0, unbillable: 0, mc_sum: 0 }
          )
          employeeSum.sum = Math.round(employeeSum.sum)
          employeeSum.unbillable = Math.round(employeeSum.unbillable)
          employeeSum.mc_sum = Math.round(employeeSum.mc_sum)
          return [
            [employeeData[1], employeeData[0]],
            [employeeSum],
            ...filledEntries,
          ]
        })
        if (this.focusOnRow !== null) {
          return all.slice(this.focusOnRow, this.focusOnRow + 1)
        }
        return all
      }
      return []
    },
  },

  watch: {
    month() {
      this.fetchHistory()
    },
  },

  mounted() {
    this.fetchHistory()
  },

  methods: {
    fetchHistory() {
      fetch(`/api/v1/time-entries/day-per-day-employees?month=${this.month}`, {
        credentials: 'same-origin',
      }).then((response) =>
        response.json().then((data) => {
          this.data = data
        })
      )
    },

    fillDates(entries) {
      // Put the dates into [sum, text-description]
      return this.data[1].map((date) => {
        const entriesForDate = entries.find((e) => e.date === date)
        if (!entriesForDate) {
          return [0, []]
        }
        const dayWorkRations = this.getDayRatio(entriesForDate.entries)
        return [
          entriesForDate.sum,
          this.textForDays(entriesForDate.entries),
          dayWorkRations.unbillable,
          dayWorkRations.mc,
        ]
      })
    },

    // calculate the unbillable and mc work during the entries of the given day
    getDayRatio(daysEntries) {
      return daysEntries.data.reduce(
        (col, de) => {
          const [, , , billableId, , , inDays, isMC] = de
          if (isMC === true) {
            col.mc += inDays
          } else if (billableId === null) {
            col.unbillable += inDays
          }
          return col
        },
        { unbillable: 0, mc: 0 }
      )
    },

    textForDays(daysEntries) {
      return daysEntries.data
        .map((de) => {
          const [client, project, , billableId, msg, tags, inDays, isMC] = de
          let billableText = 'NOT BILLABLE'
          if (billableId !== null) {
            billableText = 'YE$'
          } else if (isMC === true) {
            billableText = 'MC'
          }
          return `${client}/${project}: ${inDays}d, ${msg} (${
            tags || 'no tags'
          }) billable: ${billableText}`
        })
        .join('\n')
    },

    getCellStyles(totalWork, unbillableWork, mcWork) {
      if (totalWork === 0) {
        return ''
      }
      const unbilledRatio = unbillableWork / totalWork
      const backgroundHeight = (1.0 - Math.min(1.0, totalWork)) * 100
      const mcRatio = mcWork / totalWork
      const bottomColor = `rgb(40, ${mcRatio * 255}, ${(1.0 - mcRatio) * 255})`
      const topColor = 'rgb(255, 40, 40)'
      return {
        backgroundImage: `linear-gradient(white, ${backgroundHeight}%, ${topColor}, ${
          unbilledRatio * 100
        }%, ${bottomColor})`,
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/scss/variables';

.day-per-day-employees {
  padding: 20px 0;
  display: block;
  overflow-x: auto;

  td {
    text-align: center;
    line-height: 2em;

    &:first-child {
      line-height: 1em;
    }

    &:nth-child(2) {
      font-weight: bold;
    }

    span.name {
      cursor: pointer;
    }

    span.has-value {
      color: white;
      background: black;
      display: inline-block;
      padding: 2px 0;
      width: 80%;
    }
  }
}
</style>
