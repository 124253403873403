<template>
  <div class="entry">
    <div class="client-box">
      <div class="client-logo">
        <img
          :src="project.client.logo"
          :alt="project.name"
          :title="project.name"
        />
      </div>
      <div class="status-container" @click="handleUpdateStatus(project.id)">
        <div
          :class="{
            'project-status': true,
            active: adding === project.id,
          }"
        >
          <font-awesome-icon
            :class="['icon', status.toLowerCase()]"
            :icon="statusIcon(status)"
          />
        </div>
        <div :class="{ 'hidden-add': true, active: adding === project.id }">
          <font-awesome-icon class="icon grey" icon="plus" />
        </div>
      </div>
    </div>

    <div
      class="client-color"
      :style="{ 'background-color': project.color }"
    ></div>

    <div class="entry-detail">
      <div class="avatar">
        <img :src="employee_photo" :alt="history.email || 'Meerkat'" />
      </div>
      <div class="nested-grid">
        <div class="date">
          {{ dateTimeFormatter(history.history_date) }}
        </div>

        <div v-if="adding !== project.id" class="entry-bubble">
          <template v-if="statusChangeReason.length > 0">
            <span v-if="!isLongReasonCollapsible || !isLongReasonCollapsed">
              {{ statusChangeReason }}
            </span>

            <span v-else>
              {{ statusChangeReasonTruncated }}
            </span>

            <span
              v-if="isLongReasonCollapsible"
              class="toggle-collapsed-button"
              @click="isLongReasonCollapsed = !isLongReasonCollapsed"
            >
              {{ isLongReasonCollapsed ? '(show more)' : '(show less)' }}
            </span>
          </template>

          <span v-else> Too quiet here... </span>
        </div>

        <div v-else class="new-entry ng-form">
          <Multiselect
            v-model="newStatus"
            label="text"
            track-by="value"
            :options="statusOptions"
          />

          <textarea
            v-model="newReason"
            tabindex="0"
            :placeholder="`What's new for ${project.name}?`"
          />

          <button
            tabindex="0"
            :disabled="!newStatus || newReason.length === 0"
            @click="submitNewStatus(project.projectstatus)"
          >
            <font-awesome-icon icon="paper-plane" /> Send
          </button>
        </div>
      </div>
      <div v-if="adding !== project.id" class="allocation">
        <Allocation
          :total-allocation="Number(project.currently_allocated_days)"
          :used-allocation="Number(project.work_done_in_range)"
          :width="50"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Allocation from '@/components/employee-ng/Allocation'
import moment from 'moment/moment'
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'

const HISTORY_CHANGE_MAX_LENGTH = 200

export default {
  name: 'ProjectStatusCard',

  components: {
    Allocation,
    Multiselect,
  },

  props: {
    project: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      adding: null,
      isLongReasonCollapsed: true,
      newStatus: null,
      newReason: '',
    }
  },

  computed: {
    isLongReasonCollapsible() {
      return this.statusChangeReason.length > HISTORY_CHANGE_MAX_LENGTH
    },

    statusChangeReason() {
      return (
        this.project.projectstatus?.history?.[0].history_change_reason ??
        String()
      )
    },

    statusChangeReasonTruncated() {
      return this.statusChangeReason.slice(0, HISTORY_CHANGE_MAX_LENGTH)
    },

    statusOptions() {
      return [
        { value: 'GREY', text: '⚪️ Nothing going on' },
        { value: 'GREEN', text: '🟢 All good' },
        { value: 'YELLOW', text: '🟡 Needs attention' },
        { value: 'RED', text: '🔴 Needs urgent attention' },
      ]
    },

    status() {
      return this.project?.projectstatus?.status ?? 'GREY'
    },

    history() {
      return this.project?.projectstatus?.history?.[0]
    },

    employee_photo() {
      return this.$store.getters.employee.photo || null
    },
  },

  mounted() {
    this.resetFormData()
  },

  methods: {
    ...mapActions(['createProjectStatus']),

    handleUpdateStatus(recentProjectId) {
      if (!this.adding || this.adding !== recentProjectId) {
        this.adding = recentProjectId
      } else {
        if (
          this.newReason.length > 0 &&
          confirm('Are sure you want to cancel this entry?')
        ) {
          this.resetFormData()
        }
        this.adding = null
      }
    },

    async submitNewStatus(projectStatus) {
      if (this.newStatus) {
        const newProjectStatus = {
          ...projectStatus,
          status: this.newStatus.value,
          change_reason: this.newReason,
        }
        await this.createProjectStatus(newProjectStatus)
        this.$emit('status-updated')

        this.resetFormData()
        this.adding = null
      }
    },

    statusIcon(status) {
      if (status === 'GREEN') return 'check'
      if (status === 'YELLOW') return 'exclamation'
      if (status === 'RED') return 'exclamation-triangle'

      return 'question'
    },

    resetFormData() {
      this.newStatus = this.statusOptions.find(({ value }) => value === 'GREY')
      this.newReason = ''
    },

    dateTimeFormatter(date) {
      return moment(date).format('Do MMM, YYYY')
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/ng-forms';
@import 'src/assets/scss/variables';

.entry {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 150px;
  border: 1px solid #eee;
  margin-bottom: 20px;
  padding-top: 20px;

  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 150px 10px auto;
    grid-template-rows: auto;
    grid-row-gap: 20px;
    padding-top: 0;
  }

  .client-box {
    background-color: $atmos-violet;
    width: 100%;
    height: 100%;
    display: flex;
    justify-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .client-logo {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      flex: 1 0 50px;
      margin: 10px;
      color: $white;

      img {
        width: 100%;
      }
    }

    .status-container {
      text-align: center;
      transition: opacity 0.2s;

      .icon {
        color: #fff;
        border: 3px solid #fff;
        height: 20px;
        width: 20px;
        margin: 10px;
        padding: 10px;
        font-size: 1em;
        border-radius: 100%;
      }

      .project-status {
        &.active {
          height: 0;
          width: 0;
          opacity: 0;
        }
      }

      .hidden-add {
        height: 0;
        width: 0;
        opacity: 0;

        &.active {
          display: inline;
          cursor: pointer;
          height: 20px;
          width: 20px;
          opacity: 1;

          .icon {
            transform: rotateZ(45deg);
            transition: all 0.2s;
          }

          &:hover {
            .icon {
              border: 3px solid #d41420;
              color: #d41420;
            }
          }
        }
      }

      &:hover {
        transition: opacity 0.2s;

        .hidden-add {
          display: inline;
          cursor: pointer;
          height: 20px;
          width: 20px;
          opacity: 1;
          transition: opacity 0.2s;
        }

        .project-status {
          height: 0;
          width: 0;
          opacity: 0;
          transition: opacity 0.2s;
        }
      }

      .grey {
        border: 3px solid #fafafa;

        &.icon {
          color: #fafafa;
        }
      }

      .green {
        border: 3px solid #14d48f;

        &.icon {
          color: #14d48f;
        }
      }

      .yellow {
        border: 3px solid #f4c63d;

        &.icon {
          color: #f4c63d;
        }
      }

      .red {
        border: 3px solid #d41420;

        &.icon {
          color: #d41420;
        }
      }
    }
  }

  .client-color {
    width: 100%;
    min-height: 7px;
    margin-top: 3px;

    @media only screen and (min-width: 768px) {
      width: auto;
      height: 100%;
      margin-left: 3px;
      margin-top: 0;
    }
  }

  .entry-detail {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1em;
    width: 100%;
    margin: 20px 0;

    @media only screen and (min-width: 768px) {
      flex-flow: row nowrap;
      align-items: flex-start;
      width: auto;
      margin: 30px;
    }

    .nested-grid {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5em;
      flex: 1;
      width: calc(100% - 30px);
      margin: 0 15px;

      @media only screen and (min-width: 768px) {
        width: auto;
        margin: 0;
      }
    }

    .avatar {
      flex: 0 0 60px;
      height: 120px;
      margin-top: 1.25em;

      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        object-position: center;
        border: 2px solid $light-grey;
        border-radius: 50%;
      }
    }

    .date {
      color: #838383;
      font-size: 0.75em;
    }

    .entry-bubble {
      box-sizing: border-box;
      min-height: 100px;
      padding: 15px;
      background: #fafafa;
      border: 1px solid #eee;
      color: black;

      .toggle-collapsed-button {
        cursor: pointer;
        color: $atmos-violet;
      }
    }
  }

  .allocation {
    display: flex;
    height: 100px;
    margin-top: 1.25em;

    @media screen and (min-width: 768px) {
      flex: 0 0 150px;
    }
  }
}
</style>
