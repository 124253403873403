<template>
  <div class="time-entry-picker">
    <table>
      <tr>
        <th style="width: 5%">Week</th>
        <th class="date left" style="width: 10%">Date</th>
        <th v-if="noCheckbox !== true" class="checkbox">
          <input
            :checked="allPicked"
            type="checkbox"
            @click="pickAllToggle()"
          />
        </th>
        <th class="center" style="width: 10%">Amount</th>
        <th class="left" style="width: 10%">Project</th>
        <th class="left">Task</th>
        <th class="left" style="width: 18%">Tags</th>
        <th class="left" style="width: 120px" />
      </tr>
      <tbody>
        <template v-for="(weekData, week, weekIndex) in entriesTotal">
          <template v-for="(entry, idx) in weekData.entries">
            <tr
              v-for="(e, dataIndex) in entry.data"
              :key="`${idx}-${e.id}`"
              class="box"
            >
              <td
                v-if="idx + dataIndex === 0"
                class="week"
                :rowspan="weekData.total"
              >
                <span
                  >Week-{{
                    Math.abs(weekIndex - Object.keys(entriesTotal).length) - 1
                  }}</span
                >
              </td>
              <td v-if="dataIndex === 0" :rowspan="entry.data.length">
                {{ formatDate(e.date) }}
              </td>
              <td
                v-if="noCheckbox !== true"
                class="checkbox"
                @click="togglePick(e.id)"
              >
                <input v-model="picked" :value="e.id" type="checkbox" />
              </td>
              <td class="center">
                <input
                  v-model="e.amount"
                  class="input-field min-width-input"
                  :class="{
                    'input-mode': e.id === editEntryId,
                  }"
                  :type="e.id !== editEntryId ? 'text' : 'number'"
                  :readonly="e.id !== editEntryId"
                  style="width: 70px"
                />
                <span>{{ e.hours ? 'h' : 'd' }}</span>
              </td>
              <td>
                <template v-if="e.id !== editEntryId">
                  {{ e.project }}
                </template>
                <select v-else v-model="projId">
                  <option
                    v-for="project in projects"
                    :key="project.id"
                    :value="project.id"
                  >
                    {{ project.name }}
                  </option>
                </select>
              </td>
              <td>
                <input
                  v-model="e.msg"
                  class="input-field"
                  :class="{
                    'input-mode': e.id === editEntryId,
                  }"
                  type="text"
                  :readonly="e.id !== editEntryId"
                />
              </td>
              <td>
                <div v-if="e.id !== editEntryId" class="tags-wrapper">
                  <template v-for="(tag, id) in e.tags">
                    <Tag :key="id" :text="tag.name" />
                  </template>
                </div>
                <template v-else>
                  <multiselect
                    v-model="selectedTags"
                    :options="tags"
                    :multiple="true"
                    placeholder="Pick Tags"
                    select-label="Select"
                    deselect-label="Remove"
                    label="name"
                    track-by="name"
                    :allow-empty="true"
                  />
                </template>
              </td>
              <td>
                <template v-if="e.id !== editEntryId">
                  <button
                    v-if="deleteEntryId !== e.id && e.id !== editEntryId"
                    @click="deleteEntryId = e.id"
                  >
                    Delete
                  </button>
                  <template v-else>
                    <button @click="deleteEntry(e.id)">Confirm</button>
                    <button @click="deleteEntryId = null">Cancel</button>
                  </template>
                </template>
                <template v-if="e.id !== deleteEntryId">
                  <button
                    v-if="e.id !== editEntryId"
                    @click="editEntry(e.id, e)"
                  >
                    Edit
                  </button>
                  <template v-else>
                    <button @click="editEntrySave(e.id, e)">Save</button>
                    <button
                      @click="
                        editEntryId = null
                        e.msg = editEntryOldMsg
                      "
                    >
                      Cancel
                    </button>
                  </template>
                </template>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import Multiselect from 'vue-multiselect'

import { log } from '../utils'
import Tag from './Tag.vue'

moment.locale('en', {
  week: {
    dow: 6,
  },
})
moment.locale('en')

export default {
  components: {
    Tag,
    multiselect: Multiselect,
  },
  props: {
    month: {
      type: String,
      required: true,
    },

    entries: {
      type: Array,
      default: () => [],
    },

    noCheckbox: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editEntryId: ' ',
      editProjectData: [],
      editEntryOldMsg: '',
      picked: [],
      tags: null,
      selectedTags: [],
      allPicked: false,
      projId: '',
      projects: [],
      deleteEntryId: null,
    }
  },
  computed: {
    formattedMonth() {
      // Month comse as either 2020-9 or 2020-10 - but js date expects 2 digits month
      const m = this.month.split('-')
      if (m[1].length === 1) return `${m[0]}-0${m[1]}`
      return this.month
    },
    entriesTotal() {
      const localEntries = [...this.entries]
      localEntries.sort((a, b) => {
        if (moment(this.toDate(a)).isBefore(b.date)) {
          return 1
        }
        return -1
      })

      const byDate = {}
      const orderedByDate = {} // Group by dates
      for (const e of localEntries) {
        if (!(e.date in byDate)) byDate[e.date] = []
        byDate[e.date].push(e)
      }
      Object.keys(byDate)
        .sort(function (a, b) {
          return new Date(b) - new Date(a)
        })
        .forEach(function (key) {
          orderedByDate[key] = byDate[key]
        })
      const timeLineData = this.getWeeks()
      const firstDays = Object.keys(timeLineData)
      let w1, w2, date
      for (const e in orderedByDate) {
        // Group by weeks
        for (let i = 1; i < firstDays.length; i++) {
          w1 = moment(this.toDate(firstDays[i - 1]))
          w2 = moment(this.toDate(firstDays[i]))
          date = moment(this.toDate(e))
          if (w1 > date && w2 <= date) {
            timeLineData[firstDays[i - 1]].entries.push({
              date: e,
              data: byDate[e],
            })
            timeLineData[firstDays[i - 1]].total += byDate[e].length
          }
        }
      }
      return timeLineData
    },
  },

  mounted() {
    log('TimeEntryPicker.mounted()')
    fetch('/api/v1/projects/', { credentials: 'same-origin' })
      .then((response) => response.json())
      .then((data) => (this.projects = data))

    fetch('/api/v1/time-entries/entry_tags/', { credentials: 'same-origin' })
      .then((response) => response.json())
      .then((data) => (this.tags = data))
  },

  methods: {
    deleteEntry(id) {
      this.$emit('deleteEntry', id)
    },
    editEntry(id, entryData) {
      this.projId = entryData.project_id
      this.editEntryId = id
      this.editEntryOldMsg = entryData.msg
      this.selectedTags = entryData.tags
    },
    editEntrySave(id, entryData) {
      const tagIds = this.selectedTags.map(({ id }) => id)
      if (entryData.id === id) {
        this.editProjectData = entryData
        const entryTypeAndId = id.split('-')
        const data = {
          project: this.projId,
          msg: entryData.msg,
          tags: tagIds,
        }
        if (entryTypeAndId[0] === 'days') data.days = entryData.amount
        else data.hours = entryData.amount

        const csrfToken = document.getElementsByName('csrfmiddlewaretoken')[0]
          .value

        const payload = {
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          body: JSON.stringify(data),
        }

        const url = `/api/v1/time-entries/${entryTypeAndId[0]}/${entryTypeAndId[1]}/`

        payload.method = 'PATCH'
        fetch(url, payload)
          .then((response) => response.json())
          .then((response) => {
            this.$emit('refetchData', id)
          })
          .catch((error) => console.error('Error saving event: ', error))
      }
      this.editEntryId = null
    },
    toDate(d) {
      return new Date(d)
    },
    formatDate(d) {
      return moment(this.toDate(d)).format('ddd, D.MM.YY')
    },
    getWeeks() {
      let m = moment(this.toDate(this.formattedMonth)).startOf('month')
      const totalWeeks =
        moment(this.toDate(this.formattedMonth))
          .add(1, 'month')
          .diff(m, 'weeks') + 1
      let day
      const weeklyDistribution = {}
      const today = moment()

      weeklyDistribution[m] = { entries: [], total: 0 }
      m = m.add(7, 'days').startOf('week')

      for (let i = 0; i < totalWeeks; i++) {
        day = moment(m).add(i * 7 + 1, 'days')
        if (i > 3) {
          const lastDay = moment(m).add((i - 1) * 7 + 1, 'days')
          if (day.month() !== lastDay.month() && day <= today) {
            const start = day.clone().startOf('month')
            weeklyDistribution[start] = { entries: [], total: 0 }
            if (start.day() === 0) continue // If Month is starting on Sunday
          }
        }

        if (day > today) {
          weeklyDistribution[today] = { entries: [], total: 0 }
          break
        }
        weeklyDistribution[day] = { entries: [], total: 0 }
      }
      return Object.fromEntries(
        Object.entries(weeklyDistribution).sort((a, b) => {
          if (moment(this.toDate(a[0])).isBefore(moment(this.toDate(b[0])))) {
            return 1
          }
          return -1
        })
      )
    },
    togglePick(id) {
      if (this.picked.indexOf(id) !== -1) {
        this.picked = _.filter(this.picked, (d) => d !== id)
        this.allPicked = false
      } else {
        this.picked.push(id)
      }
      this.$emit(
        'input',
        _.filter(this.entries, (e) => this.picked.indexOf(e.id) !== -1)
      )
    },

    pickAllToggle() {
      if (this.allPicked) {
        this.picked = []
      } else {
        this.picked = _.map(this.entries, (m) => m.id)
      }
      this.allPicked = !this.allPicked
      this.$emit(
        'input',
        _.filter(this.entries, (e) => this.picked.indexOf(e.id) !== -1)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse; // 1
  border-spacing: 0;
}

td.gap > span {
  background: red;
}

tr.box {
  > td {
    border: 1px solid #656565;
  }
}

td.week {
  text-align: center;
}

.table button {
  width: 55px;
  margin: 2px;
  text-align: center;
  padding: 0;
  height: 25px;
  font-size: 9px;
  border-radius: 1px;
  background: black;
}

.input-field {
  width: 100%;
  height: 20px;
  padding: 0 0 0 2px;
  font-size: 12px;
  border: none;

  &:focus,
  &:active {
    outline: none;
  }
}

.min-width-input {
  width: 30px;
  text-align: center;
}

.input-mode,
select {
  border: 1px solid #a2a2a2;
  height: 26px;
  width: 100%;
  margin-right: 2px;
  padding: 5px;
  color: #00000c;
  font-size: 13px;
  font-weight: 600;
}

select {
  cursor: pointer;
}

.tags-wrapper {
  display: flex;
}
</style>
