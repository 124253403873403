<template>
  <span :style="{ background: backgroundColor, color }" class="tag">
    {{ text }}
  </span>
</template>

<script>
export default {
  name: 'Tag',

  props: {
    text: {
      type: String,
      default: '',
    },

    background: {
      type: String,
      default: null,
    },
  },

  computed: {
    backgroundColor() {
      if (this.background) return this.background
      let hash = 0
      for (let i = 0; i < this.text.length; i++) {
        hash = this.text.charCodeAt(i) + ((hash << 5) - hash)
      }
      let colour = '#'
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        colour += ('00' + value.toString(16)).substr(-2)
      }
      return colour
    },
    color() {
      const hexcolor = this.backgroundColor.replace('#', '')
      const r = parseInt(hexcolor.substr(0, 2), 16)
      const g = parseInt(hexcolor.substr(2, 2), 16)
      const b = parseInt(hexcolor.substr(4, 2), 16)
      const yiq = (r * 299 + g * 587 + b * 114) / 1000
      return yiq >= 128 ? '#111111' : '#f8f8f8'
    },
  },
}
</script>

<style lang="scss">
.tag {
  border-radius: 3px;
  padding: 4px 6px;
  margin-right: 2px;
}
</style>
