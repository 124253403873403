<template>
  <div class="employeeng-dash" :style="cssVars">
    <div class="title">
      <h1>
        <span
          >WELCOME HOME
          {{
            employee.user.username
              ? employee.user.username.toUpperCase()
              : 'Dear User'
          }}</span
        >
      </h1>
      <img class="profile" :src="employee.photo" alt="Image" />
    </div>
    <div class="grid">
      <div class="grid-left">
        <TimeEntrySummary class="dashboard-card" />
        <LatestTimeEntries class="dashboard-card" />
        <YourRecentProjects class="dashboard-card" />
      </div>
      <div class="grid-right">
        <Calendar class="dashboard-card" />
        <SalaryDetails class="dashboard-card" />
        <Kudos class="dashboard-card" />
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from '@/components/employee-ng/Calendar'
import Kudos from '@/components/employee-ng/Kudos'
import LatestTimeEntries from '@/components/employee-ng/LatestTimeEntries'
import SalaryDetails from '@/components/employee-ng/SalaryDetails'
import TimeEntrySummary from '@/components/employee-ng/TimeEntrySummary'
import YourRecentProjects from '@/components/employee-ng/YourRecentProjects'

export default {
  name: 'EmployeeNGDash',

  components: {
    Calendar,
    Kudos,
    LatestTimeEntries,
    SalaryDetails,
    TimeEntrySummary,
    YourRecentProjects,
  },

  props: {
    employee: {
      type: Object,
      required: true,
    },

    user: {
      type: Object,
      default: () => ({}),
    },

    logoutUrl: {
      type: String,
      default: '',
    },
  },

  computed: {
    cssVars() {
      return {
        '--employee-color': this.employee.color,
      }
    },
  },
  created() {
    this.$store.commit('setEmployee', this.employee)
  },
}
</script>

<style lang="scss">
.employeeng-dash {
  font-family: Montserrat, sans-serif;
  padding: 15px;

  $grid-breakpoint: 1240px;
  $padding-breakpoint: 1440px;

  @media only screen and (min-width: $padding-breakpoint) {
    padding: 40px 90px;
  }

  button {
    text-transform: none;
    border-radius: 3px;
    font-size: 13px;
    border: none;
    padding: 8px 10px;
    background: black;
    color: white;
    font-family: Raleway, sans-serif;
    letter-spacing: 1px;
    line-height: 1.2em;
    cursor: pointer;
    outline: none;
    transition: all 200ms;

    &:hover {
      background: #454545;
    }

    &:disabled {
      background-color: #888;
      cursor: not-allowed;
    }

    &:focus {
      outline: auto;
    }
  }

  .title {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  .grid {
    $grid-gap: 2rem;

    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-gap: $grid-gap;

    @media only screen and (min-width: $grid-breakpoint) {
      grid-template-columns: 3fr 2fr;
      grid-auto-rows: auto;
    }

    & > div {
      min-height: 60px;
      width: 100%;
    }

    .grid-left,
    .grid-right {
      display: flex;
      flex-flow: column nowrap;
      gap: $grid-gap;
    }
  }

  .dashboard-card {
    background: white;
    box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 6%);
    padding: 2rem;
    border-radius: 1rem;
  }

  .profile {
    justify-self: right;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #fafafa;
    object-fit: cover;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    margin-left: 20px;
    border: 2px solid #ddd;
  }

  h1 {
    left: 0;
    top: 10px;
    font-size: 40px;
    font-weight: bold;
    line-height: 49px;
    letter-spacing: 1px;
    text-align: left;

    span {
      border-top: 7px solid #000;
      border-bottom: 7px solid #000;
    }
  }

  h2 {
    font-size: 30px;
    font-weight: normal;
    line-height: 35px;
    letter-spacing: 1px;
    text-align: left;
    text-transform: uppercase;

    span {
      border-bottom: 4px solid #000;
    }
  }
}

.dashboard-wrapper {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 15px;
}

.sub-compo {
  margin-left: 100px;
  margin-bottom: 20px;
}

.footer-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.m-delete {
  border: 4px solid #4caf50;
}
</style>
