<template>
  <div class="table employee-view">
    <h1>
      Active Employee summary for <b>{{ project_data.name }}</b> during
      <MonthSelector v-model="month" @input="fetchData()" />
    </h1>

    <table class="overview full-width">
      <tr>
        <th class="center">Employee</th>
        <th class="center">Days Worked</th>
        <th class="center">Hours Worked</th>
      </tr>
      <tr v-for="(emp, index) in project_data.employees" :key="index">
        <td class="center">
          {{ emp.employee__name }}
        </td>
        <td class="center">
          {{ emp.dsum }}
        </td>
        <td class="center">
          {{ emp.hsum }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import moment from 'moment'

import { log } from '../../utils.js'
import MonthSelector from '../MonthSelector.vue'

export default {
  components: {
    MonthSelector,
  },
  props: {
    projectId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      month: moment().subtract(1, 'months').format('YYYY-M'),
      project_data: {
        active_employees: [],
        name: '',
      },
    }
  },

  computed: {},

  mounted() {
    log('ProjectView.mounted()')
    this.fetchData()
  },

  methods: {
    fetchData() {
      fetch(
        `/api/v1/projects/active_employees/${this.projectId}/${this.month}`,
        { credentials: 'same-origin' }
      ).then((response) =>
        response.json().then((data) => (this.project_data = data))
      )
    },
  },
}
</script>

<style lang="scss">
.employee-view {
  .average-score {
    text-align: center;
    text-transform: uppercase;
  }
}
</style>
