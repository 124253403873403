<template>
  <div class="time-entries-upload">
    <div class="buttons">
      <form @submit.prevent="uploadEntriesFile">
        <header>
          <p>Upload entries from external developers</p>
        </header>
        <div class="fileUpload">
          <label>
            <input
              id="file"
              ref="file"
              type="file"
              style="padding: 0"
              @change="handleFileUpload()"
            />
          </label>
          <button :class="{ button: true }" type="submit" :disabled="file == null">
            Upload Entries
          </button>
        </div>
      </form>
    </div>

    <div v-if="uploadComplete">
      <h3>Upload results</h3>
      <pre>{{ uploadResponse }}</pre>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

export default {
  components: {},
  props: [],

  data() {
    return {
      month: moment(),
      timeentries: [],
      openAccordions: [],
      file: null,
      uploadComplete: false,
      uploadResponse: {},
    }
  },

  computed: {
    monthName() {
      return moment(this.month, 'YYYY-MM').format('MMMM')
    },
  },

  mounted() {
    console.log('TimeEntries.mounted()')
    this.fetch()
  },

  methods: {
    refresh(monthSelectorValue) {
      this.month = moment(monthSelectorValue, 'YYYY-M')
      this.fetch()
    },

    toggleAccordion(key) {
      if (this.openAccordions.includes(key)) {
        this.openAccordions = _.remove(this.openAccordions, key)
      } else {
        this.openAccordions.push(key)
      }
    },

    downloadEntries() {
      const url = `/api/v1/employees/work-report/${this.month.format('YYYY-MM-DD')}`
      console.log("oh yeah! let's go and direct to ->", url)
      window.open(url, '_blank')
    },

    fetch() {
      fetch(
        `/api/v1/time-entries/monthly/${this.month.format('YYYY')}-${this.month.format('MM')}`,
        {
          credentials: 'same-origin',
        }
      ).then((response) =>
        response.json().then((data) => {
          this.timeentries = data
        })
      )
    },

    handleFileUpload() {
      console.log('file uploaded')
      this.file = this.$refs.file.files[0]
    },

    getCookie(name) {
      /// Retrieve cookie function
      const match = document.cookie.match(new RegExp(name + '=([^;]+)'))
      if (match) return match[1]
    },

    async uploadEntriesFile() {
      const csrfToken = this.getCookie('csrftoken')
      const formData = new FormData()

      if (this.file) {
        formData.append('file', this.file)
      } else {
        console.log('No file selected')
      }

      console.log('form data  => ', formData.get('file'))
      const payload = {
        credentials: 'same-origin',
        headers: {
          'X-CSRFToken': csrfToken,
        },
        body: formData,
      }

      console.log(payload)

      const url = '/api/v1/employees/external/'
      payload.method = 'POST'

      fetch(url, payload)
        .then((response) => {
          // log("Success", response);
          // log("Upload response", response);
          if (response.status === 200) {
            this.uploadComplete = true
            return response.json()
          } else if (response.status > 400) {
            this.uploadComplete = true
            return {
              status: response.status,
              error: response.text(),
            }
          }
        })
        .then((data) => (this.uploadResponse = data))
        .catch((error) => {
          console.error('Error uploading file: ', error)
          this.uploadResponse = { error: error.toString() }
        })
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/scss/variables';

.time-entries-upload {
  form {
    display: flex;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid $light-grey;

    > * {
      flex: 1;
    }
  }
}
</style>
