<template>
  <div class="main">
    <div class="header-container">
      <h2>{{ header }}</h2>
      <div class="buttons">
        <button
          :disabled="loading"
          class="button"
          @click="openProjectDetail = !openProjectDetail"
        >
          {{ openProjectDetail ? 'Close' : 'View' }} project detail
        </button>
      </div>
    </div>
    <div class="loader">
      <Spinner
        v-if="loading"
        class="spinner"
        name="pacman"
        :color="employee.color"
      />
      <p v-if="!loading && recentProjects.length === 0" class="spinner">
        No recent projects :-(
      </p>
    </div>
    <div v-if="openProjectDetail" class="project-detail">
      <Multiselect
        v-model="selectedProject"
        placeholder="Choose project"
        label="name"
        class="project-select"
        track-by="name"
        :allow-empty="true"
        :multiple="false"
        :options="hiddenProjects"
      />
      <div v-if="projectDetail !== null">
        <ProjectStatusCard
          :project="projectDetail"
          @status-updated="updateSelectedProject"
        ></ProjectStatusCard>
      </div>
      <p class="footer">
        <small>
          <em>
            Tip: Don't worry, your recent projects are still waiting for you
            there when you close the detail view ;)
          </em>
        </small>
      </p>
    </div>
    <div v-else>
      <ProjectStatusCard
        v-for="recentProject in recentProjects"
        :key="recentProject.id"
        :project="recentProject"
        @status-updated="fetchRecentProjects()"
      ></ProjectStatusCard>
    </div>
  </div>
</template>

<script>
import ProjectStatusCard from '@/components/employee-ng/ProjectStatusCard.vue'
import Multiselect from 'vue-multiselect'
import Spinner from 'vue-spinkit'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'YourRecentProjects',
  components: {
    Multiselect,
    ProjectStatusCard,
    Spinner,
  },

  data() {
    return {
      loading: false,
      openProjectDetail: false,
      selectedProject: null,
      projectDetail: null,
    }
  },

  computed: {
    ...mapState({
      recentProjects: (state) => state.ngDashboard.recentProjects,
      allProjects: (state) => state.ngDashboard.projects,
      employee: (state) => state.ngDashboard.employee,
    }),

    header() {
      if (this.openProjectDetail) {
        if (this.projectDetail !== null) return this.projectDetail.name
        return 'Project detail'
      }
      return 'Your recent projects'
    },

    hiddenProjects() {
      return this.allProjects.filter(
        (project) => !this.recentProjects.find((rp) => rp.id === project.id)
      )
    },
  },

  watch: {
    selectedProject(newVal, oldVal) {
      if (newVal !== null) {
        if (newVal !== oldVal) {
          this.fetchProjectStatus(newVal.id).then((project) => {
            this.projectDetail = project
          })
        }
      } else {
        this.projectDetail = null
      }
    },
  },

  mounted() {
    this.loading = true
    this.fetchRecentProjects().finally(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapActions(['fetchRecentProjects', 'fetchProjectStatus']),

    async updateSelectedProject() {
      this.projectDetail = await this.fetchProjectStatus(
        this.selectedProject.id
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      gap: 10px;
    }
  }

  .project-select {
    margin-bottom: 1.5rem;
  }

  .project-detail {
    .footer {
      text-align: center;
    }
  }

  .loader {
    display: flex;

    .spinner {
      margin: 50px auto;
    }
  }
}
</style>
