<template>
  <div class="employee-stats admin-vue-section">
    <div class="download-excel">
      <h1>Download accomplished work from the given month</h1>

      <div>
        <MonthSelector v-model="month" :months-in-future="0" @input="refresh" />
        <button @click="downloadEntries">Download entries</button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

import MonthSelector from '../MonthSelector.vue'

export default {
  components: {
    MonthSelector,
  },
  props: {
    employeeId: {
      default: '',
      type: [String, Number],
    },
  },

  data() {
    return {
      month: moment(),
      stats: {
        utilization: 0,
        potential: 0,
        utilization_history: {
          month_wise_scores: [],
          mean_score: 0,
        },
      },
      titles: ['Utilization billables', 'Utilization all', 'Potential'],
      color: ['#e444db', '#7BABE4', '#8ED69A'],
    }
  },

  computed: {
    utilizationPercentage() {
      return ((this.stats.utilization / this.stats.potential) * 100).toFixed(1)
    },

    labels() {
      return _.map(this.stats.utilization_history.month_wise_scores, (d) => d.month)
    },

    series() {
      const utilizationBillable = _.map(
        this.stats.utilization_history.month_wise_scores,
        (d) => d.score_billable
      )
      const utilizationAll = _.map(
        this.stats.utilization_history.month_wise_scores,
        (d) => d.score_all
      )
      const potential = _.map(
        this.stats.utilization_history.month_wise_scores,
        (d) => d.potential
      )
      return [utilizationBillable, utilizationAll, potential]
    },
  },

  mounted() {
    console.log('EmployeeView.mounted()')
    this.fetchData()
  },

  methods: {
    refresh(monthSelectorValue) {
      this.month = moment(monthSelectorValue, 'YYYY-M')
    },

    downloadEntries() {
      const url = `/api/v1/employees/work-report/${this.month.format('YYYY-MM-DD')}`
      console.log("oh yeah! let's go and direct to ->", url)
      window.open(url, '_blank')
    },

    fetchData() {
      fetch(`/api/v1/employees/stats?employee=${this.employeeId}`, {
        credentials: 'same-origin',
      }).then((response) =>
        response.json().then((stats) => {
          this.stats = stats
        })
      )
    },
  },
}
</script>

<style lang="scss">
.employee-stats {
  .download-excel > div {
    display: flex;
    gap: 20px;
    align-items: center;
  }
}
</style>
