<template>
  <div class="event-calendar-ng">
    <div class="event-calendar-ng-body">
      <div class="event-calendar-ng-header">
        <template v-if="showCalendar">
          <IconButton
            class="switch-month-button"
            icon="chevron-circle-left"
            @click="handleClickPrev"
          />
          <IconButton
            class="switch-month-button"
            icon="chevron-circle-right"
            @click="handleClickNext"
          />
        </template>

        <IconButton
          class="add-new-entry-button"
          icon="plus-circle"
          @click="openAddEntry"
        />
      </div>

      <FullCalendar
        v-if="showCalendar"
        ref="smallCalendar"
        class="custom-full-calendar"
        :options="config"
      />

      <CalendarEventList v-else :event-list="events" limit-height />
    </div>

    <div class="event-calendar-ng-footer">
      <button @click="showCalendar = !showCalendar">
        <FontAwesomeIcon v-if="showCalendar" icon="bars" />
        <FontAwesomeIcon v-else icon="calendar" />
      </button>

      <button class="button" @click="showDrawer">Open Calendar</button>
    </div>

    <Drawer
      class="event-calendar-ng-drawer"
      :is-drawer-open="isDrawerOpen || isCalendarRoute"
      open-from="right"
      @closeDrawer="closeDrawer"
    >
      <div class="event-calendar-ng-body">
        <div class="event-calendar-ng-header">
          <div class="checkbox-container">
            <input
              id="user-events"
              v-model="isShowAllEventsEnabled"
              type="checkbox"
            />

            <label for="user-events">Show all</label>
          </div>

          <IconButton
            class="switch-month-button"
            icon="chevron-circle-left"
            @click="handleClickPrev"
          />
          <IconButton
            class="switch-month-button"
            icon="chevron-circle-right"
            @click="handleClickNext"
          />
          <IconButton
            class="add-new-entry-button"
            icon="plus-circle"
            @click="showModal"
          />
        </div>

        <FullCalendar
          ref="drawerCalendar"
          class="custom-full-calendar"
          :options="configExpanded"
        />

        <CalendarEventList :event-list="events" />
      </div>

      <Modal v-if="isModalVisible" @close="closeModal">
        <h2 v-if="editEventObj.id">Edit event details</h2>
        <h2 v-else>Enter a few details</h2>

        <EventForm
          :edit-event="editEventObj"
          :prefill-dates="selectedDateRange"
          :employee="employee"
          @addEvent="handleAddEvent"
          @editEvent="handleEditEvent"
          @deleteEvent="handleDeleteEvent"
        />
      </Modal>
    </Drawer>
  </div>
</template>

<script>
import CalendarEventList from '@/components/employee-ng/CalendarEventList'
import IconButton from '@/components/employee-ng/IconButton'
import CalendarMixin from '@/components/mixins/CalendarMixin'
import { dateToString } from '@/utils'
import FullCalendar from '@fullcalendar/vue'
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'

import Drawer from '../Drawer.vue'
import Modal from '../Modal.vue'
import EventForm from './NGEventForm.vue'

export default {
  name: 'Calendar',

  components: {
    CalendarEventList,
    Drawer,
    EventForm,
    IconButton,
    FullCalendar,
    Modal,
  },

  mixins: [CalendarMixin],

  props: {
    hideForm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editEventObj: {},
      isDrawerOpen: false,
      isModalVisible: false,
      isShowAllEventsEnabled: null,
      selectedDateRange: null,
      showCalendar: true,
      currentMonth: moment().format('YYYY-MM'),
    }
  },

  computed: {
    ...mapState({
      allEvents: (state) => state.ngDashboard.events,
      users: (state) => state.ngDashboard.users,
    }),

    ...mapGetters(['ownEvents', 'employee']),

    events() {
      return this.isShowAllEventsEnabled ? this.allEvents : this.ownEvents
    },

    calendarEventHandlers() {
      return {
        eventOrder: this.handleEventOrder,
        eventClick: this.handleEventClick,
        eventDrop: this.handleEventDrop,
        eventAdd: this.handleEventAdded,
        select: this.handleSelect,
      }
    },

    config() {
      return {
        ...this.baseConfig,
        ...this.calendarEventHandlers,
        events: this.events,
        contentHeight: 440,
      }
    },

    configExpanded() {
      return {
        ...this.baseConfig,
        ...this.calendarEventHandlers,
        dayMaxEvents: 6,
        events: this.events,
        contentHeight: 900,
      }
    },

    isCalendarRoute() {
      return (
        this.$route.name === 'calendar' || this.$route.name === 'calendar-add'
      )
    },
  },

  watch: {
    isShowAllEventsEnabled(value) {
      this.setCalendarShowAllPreference(value)
    },
  },

  async mounted() {
    await this.fetchEvents(this.currentMonth)

    if (!('calendar-showall' in localStorage)) {
      this.setCalendarShowAllPreference(false)
    }

    this.isShowAllEventsEnabled = JSON.parse(
      localStorage.getItem('calendar-showall')
    )
  },

  methods: {
    ...mapActions(['fetchEvents', 'createEvent', 'updateEvent', 'deleteEvent']),

    refetchData() {
      const newDate =
        this.$refs.drawerCalendar?.getApi?.().getDate() ?? new Date()
      this.currentMonth = moment(newDate).format('YYYY-MM')
      this.fetchEvents(this.currentMonth)
    },

    handleClickNext() {
      this.$refs.drawerCalendar?.getApi?.().next()
      this.$refs.smallCalendar?.getApi?.().next()
      this.refetchData()
    },

    handleClickPrev() {
      this.$refs.drawerCalendar?.getApi?.().prev()
      this.$refs.smallCalendar?.getApi?.().prev()
      this.refetchData()
    },

    showModal() {
      this.isModalVisible = true
      this.isDrawerOpen = true
      this.$router.push({ name: 'calendar-add' })
    },

    closeModal() {
      this.isModalVisible = false
      this.$router.push({ name: 'calendar' })
      this.editEventObj = {}
    },

    showDrawer() {
      if (!this.isCalendarRoute) {
        this.$router.push({ name: 'calendar' })
      }

      // The render has to be manually triggered because the container does not exist before it opens
      setTimeout(() => {
        this.$refs.drawerCalendar.getApi().render()
      }, 300)
      this.isDrawerOpen = true
    },

    closeDrawer() {
      if (this.isCalendarRoute) {
        this.$router.push({ name: 'dashboard' })
      }
      this.isModalVisible = false
      this.isDrawerOpen = false
    },

    openAddEntry() {
      this.isDrawerOpen = true
      this.showModal()
    },

    setCalendarShowAllPreference(value) {
      localStorage.setItem('calendar-showall', value)
    },

    handleSelect(selectionInfo) {
      const start = selectionInfo.start
      const end = selectionInfo.end

      end.setDate(end.getDate() - 1)

      this.editEventObj = {}
      this.selectedDateRange = { start, end }
    },

    handleEventOrder(a, b) {
      // eslint-disable-next-line eqeqeq
      if (this.employee != null) {
        return a.employee?.id === this.employee.id ? -1 : 1
      }
      return a.employee?.name < b.employee?.name
    },

    handleEventDataTransform(eventData) {
      // In FullCalendar, start/end date are exclusive, but in our backend they can be on the same day.
      // So, in order to display events correctly, we have to add +1 to the end date we have stored
      // https://fullcalendar.io/docs/event-parsing
      const end = new Date(eventData.end)
      end.setDate(end.getDate() + 1)

      return {
        ...eventData,
        allDay: true,
        editable: eventData.employee?.id === this.employee.id,
        end,
        title: eventData.is_public_holiday
          ? eventData.description
          : `${eventData.employee?.user?.username} - ${eventData.description}`,
        color: `${eventData.color}AA`, // Append the "alpha" characters to make the event semi-transparent
      }
    },

    handleEventClick(eventClickInfo) {
      if (
        eventClickInfo.event.extendedProps.employee?.id !== this.employee.id
      ) {
        alert("You can't view other people's events!")
        return
      }

      this.editEventObj = eventClickInfo.event
      this.showModal()
    },

    async handleAddEvent(newEvent, file) {
      try {
        await this.createEvent(newEvent, file)
      } catch (e) {
        alert(`Something went wrong!\n\n${e}`)
      } finally {
        this.closeModal()
      }
    },

    async handleDeleteEvent(eventId) {
      try {
        await this.deleteEvent(eventId)
      } catch (e) {
        alert(`Something went wrong!\n\n${e}`)
      } finally {
        this.closeModal()
      }
    },

    async handleEditEvent(event, file) {
      try {
        await this.updateEvent(event, file)
      } catch (e) {
        alert(`Something went wrong!\n\n${e}`)
      } finally {
        this.closeModal()
      }
    },

    async handleEventDrop(eventDropInfo) {
      if (eventDropInfo.event.extendedProps.employee?.id !== this.employee.id) {
        alert("You can't modify other people's events!")
        eventDropInfo.revert()
        return
      }
      const { event } = eventDropInfo

      // In FullCalendar, start/end date are exclusive, but in our backend they can be on the same day.
      // So, in order to save events correctly, we have to subtract one day to the end date that FC gives
      // https://fullcalendar.io/docs/event-parsing
      const end = new Date(event.end)
      end.setDate(end.getDate() - 1)

      const droppedEvent = {
        id: event.id,
        employee: this.employee.id,
        title: event.title.split(' - ').pop(),
        start: dateToString(event.start),
        end: dateToString(end),
      }

      try {
        await this.updateEvent(droppedEvent, null)
      } catch (e) {
        alert(`Something went wrong!\n\n${e}`)
        eventDropInfo.revert()
      }
    },

    handleEventAdded(evt) {
      this.selectedDateRange = { start: evt.start, end: evt.end }
    },
  },
}
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
@import 'src/assets/scss/full-calendar';

$add-new-entry-button-color: hsl(158deg, 83%, 45%);
$switch-month-button-color: hsl(197deg, 84%, 36%);

.event-calendar-ng {
  display: flex;
  flex-flow: column nowrap;
  gap: 1em;

  .event-calendar-ng-header,
  .event-calendar-ng-footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5em;
    margin-left: auto;
  }

  .event-calendar-ng-header {
    position: absolute;
    right: 0;
  }

  .event-calendar-ng-body {
    position: relative;

    .checkbox-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.25em;

      input[type='checkbox'] {
        width: 1.5em;
        height: 1.5em;
      }
    }

    .add-new-entry-button {
      color: $add-new-entry-button-color;
    }

    .switch-month-button {
      color: $switch-month-button-color;
    }
  }
}

.event-calendar-ng-drawer {
  .checkbox-container {
    margin-right: 2em;
  }

  .event-calendar-ng-body {
    display: flex;
    flex-flow: column nowrap;
    gap: 4em;
  }
}
</style>
