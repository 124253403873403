<template>
  <label class="big-box">
    {{ title }}
    <span v-html="value" />
    <p v-html="text" />
  </label>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/scss/variables';

.big-box {
  display: block;
  padding: 10px;
  margin: 30px;
  border: 1px solid $orange;
  background: $light-grey;
  color: $blue;
  text-align: center;
  font-size: 18px;

  span {
    display: block;
    font-size: 3rem;
    min-height: 75%;
  }

  p {
    font-size: 0.6rem;
  }
}
</style>
