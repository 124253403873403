<template>
  <TimeEntryForm ref="timeEntryForm" @submit="createNewTimeEntry" />
</template>

<script>
import TimeEntryForm from '@/components/employee-ng/TimeEntryForm'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'AddTimeEntry',

  components: {
    TimeEntryForm,
  },

  data() {
    return {
      currentMonth: moment().format('YYYY-M'),
    }
  },

  methods: {
    ...mapActions(['addEntry', 'fetchEntries']),

    async createNewTimeEntry(entry) {
      try {
        await this.addEntry(entry)
        await this.fetchEntries(this.currentMonth, null)
        this.$refs.timeEntryForm && this.$refs.timeEntryForm.clear()
      } catch (error) {
        console.error(`Creating new entry went bananas: ${error}`)
      }
    },
  },
}
</script>
