<template>
  <div class="input-field">
    <font-awesome-icon :icon="['fas', icon]" class="icon" />

    <input
      type="text"
      :value="value"
      :placeholder="getPlaceholder"
      @input="updateValue($event.target.value)"
      @keyup.enter="onPageChange($event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      default: '',
    },

    icon: {
      type: String,
      default: '',
    },
  },
  computed: {
    getPlaceholder() {
      return this.placeholder
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value)
    },

    onPageChange(event) {
      event.target.blur()
    },
  },
}
</script>

<style lang="scss" scoped>
.input-field {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  height: 35px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #e8e8e8;
  padding: 0 5px;
  align-items: center;

  .icon {
    margin: 0 5px;
  }

  input {
    font-weight: 400;
    font-style: italic;
    font-size: 13px;
    border: none;
    width: 90%;
    padding: 5px 0 5px 5px;
    font-family: Montserrat, sans-serif;

    &:focus {
      border: none;
      outline: none;
    }

    &::placeholder {
      color: #838383;
      font-family: Montserrat, sans-serif;
    }
  }
}
</style>
