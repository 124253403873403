import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

const CalendarMixin = {
  computed: {
    baseConfig() {
      return {
        editable: true,
        selectable: true,
        initialView: 'dayGridMonth',
        plugins: [dayGridPlugin, interactionPlugin],
        weekNumberCalculation: 'ISO', // To start week from `Monday`
        weekNumbers: true,
        displayEventTime: false,
        dayMaxEvents: 2,
        defaultAllDay: true,
        headerToolbar: {
          left: 'title',
          center: false,
          right: false,
        },
        buttonText: {
          today: null,
        },
        eventDataTransform: this.handleEventDataTransform,
      }
    },
  },
}

export default CalendarMixin
