<template>
  <div class="project-graphs">
    <MonthlyProjectDistribution />
  </div>
</template>

<script>
import moment from 'moment'

import { log } from '../utils'
import MonthlyProjectDistribution from './MonthlyProjectDistribution.vue'

export default {
  components: {
    MonthlyProjectDistribution,
  },
  props: [],

  data() {
    return {
      values: [],
      month: moment().subtract(1, 'months').format('YYYY-MM'),
    }
  },

  computed: {},

  mounted() {
    log('ProjectGraphs.mounted()')
  },
}
</script>
