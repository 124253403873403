<template>
  <FontAwesomeIcon
    class="icon-button"
    :class="{ disabled }"
    :icon="icon"
    @click="$emit('click')"
  />
</template>

<script>
export default {
  name: 'IconButton',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-button {
  width: 2em;
  height: 2em;
  transition: opacity 200ms ease-in-out;

  &.disabled {
    filter: grayscale(100%);
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not(.disabled) {
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
