<template>
  <form class="ng-event-form ng-form" @submit.prevent="handleSubmit">
    <div class="field-group">
      <label>Title</label>
      <input
        v-model="event.title"
        class="input-field"
        type="text"
        placeholder="Enter a short yet meaningful title"
        required
      />
    </div>

    <div v-if="!editMode" class="field-group">
      <label>Description</label>

      <textarea
        v-model="event.description"
        class="input-field"
        placeholder="Give some additional information (only sent as an email to HR)"
        required
      />
    </div>

    <div class="field-group field-start-date">
      <label>Start date</label>
      <DatePicker
        v-model="event.start"
        placeholder="Pick a date"
        monday-first
      />
    </div>

    <div class="field-group field-end-date">
      <label>End date</label>
      <DatePicker v-model="event.end" placeholder="Pick a date" monday-first />
    </div>

    <div class="actions">
      <div class="error-message">
        <p v-if="error !== null" class="error">
          {{ error }}
        </p>
      </div>

      <button v-if="editMode" class="delete-btn" @click.prevent="handleDelete">
        Delete
      </button>

      <button type="submit">
        <span v-if="editMode">Save changes</span>
        <span v-else>Create event</span>
      </button>
    </div>
  </form>
</template>

<script>
import { dateToString } from '@/utils'
import DatePicker from 'vuejs-datepicker'

export default {
  name: 'EventForm',

  components: {
    DatePicker,
  },

  props: {
    employee: {
      type: Object,
      required: true,
    },

    prefillDates: {
      type: Object,
      default: () => ({ start: null, end: null }),
    },

    editEvent: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      event: {
        title: '',
        description: '',
        start: this.prefillDates?.start,
        end: this.prefillDates?.end,
        employee: this.employee.id,
      },
      error: null,
    }
  },

  computed: {
    editMode() {
      // eslint-disable-next-line eqeqeq
      return this.editEvent?.id != null
    },
  },

  watch: {
    'event.start'(newStart) {
      if (this.event.end === '') {
        this.event.end = newStart
      }
    },
    editEvent(updatedEvent) {
      if (updatedEvent.id) {
        this.setEvent(updatedEvent)
      } else {
        this.resetValues()
      }
    },
  },

  mounted() {
    if (this.editEvent.id) {
      this.setEvent(this.editEvent)
    }
  },

  methods: {
    setEvent(event) {
      this.event.start = event.start
      this.event.end = event.end

      // In FullCalendar, start/end date are exclusive, but in our backend they can be on the same day.
      // So, in order to save events correctly, we have to subtract one day to the end date that FC gives
      // https://fullcalendar.io/docs/event-parsing
      this.event.end.setDate(event.end.getDate() - 1)

      // remove users name from title (if present)
      this.event.title = event.title.split(' - ').pop()
      this.event.id = event.id
      this.event.employee = event.extendedProps.employee.id
    },

    validateDates() {
      console.debug('VALIDATE DATES')
      this.error = null

      if (this.event.start === '' || this.event.end === '') {
        this.error = 'Please check start and end dates'
      } else if (this.event.start > this.event.end) {
        this.error = 'End date should come after start date'
      }

      if (this.error !== null) {
        console.log('ERROR', this.error)
      }
      return this.error === null
    },

    handleSubmit() {
      if (!this.validateDates()) {
        return alert(this.error)
      }

      const mode = !this.editMode ? 'addEvent' : 'editEvent'
      this.event.start = dateToString(this.event.start)
      this.event.end = dateToString(this.event.end)

      this.$emit(mode, this.event)
      this.resetValues()
    },

    handleDelete() {
      this.$emit('deleteEvent', this.event.id)
    },

    resetValues() {
      this.event = {
        title: '',
        description: '',
        start: this.prefillDates?.start,
        end: this.prefillDates?.end,
        employee: this.employee.id,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/ng-forms';

.ng-event-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1em;
  row-gap: 0.5em;

  & > * {
    grid-column: 1 / -1;
  }

  @media screen and (min-width: 768px) {
    .field-start-date {
      grid-column: 1 / 2;
    }

    .field-end-date {
      grid-column: 2 / -1;
    }

    .actions {
      flex-flow: row nowrap;
    }
  }
}
</style>
