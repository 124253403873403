<template>
  <div class="month-selector">
    <select v-model="value" class="select-css" @change="update()">
      <option v-for="(d, index) in dateRange" :key="index" :value="d">
        {{ d }}
      </option>
    </select>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    monthsInFuture: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return { value: moment().format('YYYY-M') }
  },

  computed: {
    dateRange() {
      const values = []
      const targetDate = moment().add(this.monthsInFuture, 'month')
      const startYear = 2017
      for (let curY = startYear; curY <= targetDate.year(); curY++) {
        for (let curM = 0; curM < 12; curM++) {
          if (curY === targetDate.year() && curM > targetDate.month()) {
            break
          }
          values.push(`${curY}-${curM + 1}`)
        }
      }
      return values.reverse()
    },
  },

  methods: {
    update() {
      this.$emit('input', this.value)
    },
  },
}
</script>

<style lang="scss">
.month-selector {
  margin-left: 20px;
  display: inline-block;
}

.select-css {
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 400;
  color: #444;
  line-height: 1.3;
  padding: 0.4em 1.5em 0.3em 0.6em;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 4%);
  border-radius: 0.2em;
  appearance: none;
  background-color: #fff;
  background-image: url('../assets/images/br_down.png'),
    linear-gradient(to bottom, #fff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;

  &::-ms-expand {
    display: none;
  }

  &:hover {
    border-color: #888;
    cursor: pointer;
  }

  &:focus {
    border-color: #aaa;
    color: #222;
    outline: none;
  }

  option {
    font-weight: normal;
  }
}
</style>
