<template>
  <div class="invoicing-per-client table">
    <h1>
      Client Invoicing in {{ monthName }}
      <LuxonMonthSelector
        v-model="month"
        :months-in-future="0"
        :initial-date="month"
        @input="refresh"
      />
    </h1>

    <div v-if="grandTotals.grand_total" class="boxes">
      <BigBox
        :value="`${grandTotals.grand_total} €`"
        title="Grand total"
        text="The sum of all billings"
      />
      <BigBox
        :value="`${grandTotals.total_days} d`"
        title="Total days"
        text="Total days worked"
      />
    </div>

    <h2>Active clients</h2>
    <p></p>
    <div v-if="activeClients.length > 0" class="clients">
      <invoicing-client-box
        v-for="clientData in activeClients"
        :key="clientData.id"
        :client="clientData"
        :month="month"
      />
    </div>
    <strong v-else>No data for selected month</strong>

    <h2>No-profits</h2>
    <p>Clients that don't have any billing coming in</p>
    <div v-if="zeroBillingClients.length > 0" class="zero-billing-clients">
      <invoicing-client-box
        v-for="clientData in zeroBillingClients"
        :key="clientData.id"
        :client="clientData"
        :month="month"
      />
    </div>
    <strong v-else>No data for selected month</strong>

    <h2>Inactives</h2>
    <p>Clients that don't have projects marked active</p>
    <div v-if="inactiveClients.length > 0" class="clients">
      <ul>
        <li v-for="client in inactiveClients" :key="client.id">{{ client.client_name }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

import { findGetParameter } from '../../utils'
import LuxonMonthSelector from '../LuxonMonthSelector.vue'
import BigBox from './BigBox.vue'
import InvoicingClientBox from './InvoicingClientBox.vue'

export default {
  components: { LuxonMonthSelector, BigBox, InvoicingClientBox },
  props: [],

  data() {
    const dateInQuery = findGetParameter('date')
    const date = dateInQuery !== null ? DateTime.fromISO(dateInQuery) : DateTime.now()
    return {
      month: date,
      activeClients: [],
      zeroBillingClients: [],
      inactiveClients: [],
      grandTotals: {
        grand_total: 0,
        total_days: 0,
      },
    }
  },

  computed: {
    monthName() {
      return this.month.toFormat('MMMM, yyyy')
    },

    tableHeadings() {
      return this.activeClients.length > 0 ? Object.keys(this.activeClients[0]) : []
    },
  },

  mounted() {
    this.fetch()
  },

  methods: {
    refresh(monthSelectorValue) {
      // update the GET date param
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('date', this.month.toISODate())
      const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
      history.pushState(null, '', newRelativePathQuery)
      this.fetch()
    },

    fetch() {
      fetch(`/api/v1/invoicing/invoicing-per-client/${this.month.toFormat('yyyy-MM')}`, {
        credentials: 'same-origin',
      }).then((response) =>
        response.json().then((data) => {
          console.log(data)
          const perClient = data.per_client
          const actives = []
          const inactives = []
          const zeroes = []
          for (const client of perClient) {
            if (client.active === true) {
              if (client.billing_sum > 0) {
                actives.push(client)
              } else {
                zeroes.push(client)
              }
            } else {
              inactives.push(client)
            }
          }
          this.activeClients = actives.sort((a, b) =>
            a.client_name.localeCompare(b.client_name)
          )
          this.zeroBillingClients = zeroes
          this.inactiveClients = inactives
          this.grandTotals = data.grand_totals
        })
      )
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/scss/variables';

.invoicing-per-client {
  h2 {
    margin-top: 40px;
  }

  .boxes {
    display: flex;
    width: 100%;

    .big-box {
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  .clients,
  .zero-billing-clients {
    > * {
      margin: 10px 0;
    }
  }

  .zero-billing-clients {
    .accordion {
      header {
        background: $light-grey;
      }
    }
  }
}
</style>
