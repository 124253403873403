import Vue from 'vue'

import PieChart from './components/PieChart'
import ProjectGraphs from './components/ProjectGraphs.vue'
import PublicEventsUpload from './components/PublicEventsUpload'
import Tag from './components/Tag'
import TimeEntryGraphs from './components/TimeEntryGraphs.vue'
import ClientView from './components/admin/ClientView.vue'
import DownloadEmployeeWork from './components/admin/DownloadEmployeeWork.vue'
import InvoicingPerClientVue from './components/admin/InvoicingPerClient.vue'
import InvoicingPerEmployeeVue from './components/admin/InvoicingPerEmployee.vue'
import ProjectAllocations from './components/admin/ProjectAllocations'
import ProjectView from './components/admin/ProjectView'
import TimeEntriesUpload from './components/admin/TimeEntriesUpload.vue'
import EmployeeNGIndex from './components/employee-ng/EmployeeNGDash.vue'
import { log } from './utils'

Vue.config.productionTip = false

// Vue.component('WeeklyIndex', WeeklyIndex)
Vue.component('ProjectGraphs', ProjectGraphs)
Vue.component('ProjectView', ProjectView)
Vue.component('ProjectAllocations', ProjectAllocations)
Vue.component('ClientView', ClientView)
Vue.component('TimeEntryGraphs', TimeEntryGraphs)
Vue.component('EmployeeNg', EmployeeNGIndex)
Vue.component('PublicEventsUpload', PublicEventsUpload)
Vue.component('PieChart', PieChart)
Vue.component('Tag', Tag)
Vue.component('TimeEntriesUpload', TimeEntriesUpload)
Vue.component('InvoicingPerClient', InvoicingPerClientVue)
Vue.component('InvoicingPerEmployee', InvoicingPerEmployeeVue)
Vue.component('DownloadEmployeeWork', DownloadEmployeeWork)

require('./assets/scss/admin/index.scss')

if (document && document.getElementById('app')) {
  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    mounted() {
      log('main-admin.js: mounted')
    },
  })
} else {
  console.info('main-admin.js: No #app, no Vue')
}
