<template>
  <div class="main">
    <div class="heading-container">
      <h2>Latest Time Entries</h2>
      <div class="buttons">
        <button :disabled="loading" class="button" @click="isOpen = !isOpen">
          {{ isOpen ? 'Close' : 'Add Entry' }}
        </button>

        <button
          :disabled="loading"
          class="button"
          @click.capture="openAddEntries()"
        >
          View all
        </button>
      </div>
    </div>

    <transition name="fade">
      <div v-if="isOpen" class="add-time-entry-container">
        <AddTimeEntry />
      </div>
    </transition>

    <EditTimeEntry
      v-if="editableTimeEntry"
      :time-entry="editableTimeEntry"
      @dismiss="editableTimeEntry = null"
    />

    <TimeEntriesTable
      :entries="combinedEntriesLimited"
      :loading="loading"
      @delete-entry="handleDeleteEntry"
      @edit-entry="handleEditEntry"
    />

    <drawer
      :is-drawer-open="isDrawerOpen || routeEntriesActivated"
      :open-from="openFrom"
      @closeDrawer="closeAddEntries()"
    >
      <div class="title-container">
        <h1 class="title">Time Entries / {{ monthName }}</h1>

        <div class="title-buttons">
          <IconButton
            icon="chevron-circle-left"
            color="#0F7CA7"
            @click="changeMonth(-1)"
          />

          <IconButton
            icon="chevron-circle-right"
            color="#0F7CA7"
            @click="changeMonth(1)"
          />

          <IconButton
            v-if="!isOpen"
            class="plus-button"
            icon="plus-circle"
            color="#14D48F"
            @click="isOpen = !isOpen"
          />

          <IconButton
            v-else
            class="cross-button"
            icon="plus-circle"
            @click="isOpen = !isOpen"
          />
        </div>
      </div>

      <div class="filter-container">
        <CustomInput
          v-model="searchQuery"
          placeholder="Filter entries based on project, tag or comment"
          icon="filter"
        />
        <p>
          Total work: {{ showingCombinedWork }} / {{ totalCombinedWork }} days
        </p>
      </div>

      <transition name="fade">
        <div v-if="isOpen" class="add-time-entry-container">
          <AddTimeEntry />
        </div>
      </transition>

      <TimeEntriesTable
        :entries="filteredEntries"
        :loading="loading"
        @delete-entry="handleDeleteEntry"
        @edit-entry="handleEditEntry"
      />
    </drawer>
  </div>
</template>

<script>
import CustomInput from '@/components/CustomInput'
import Drawer from '@/components/Drawer.vue'
import AddTimeEntry from '@/components/employee-ng/AddTimeEntry'
import EditTimeEntry from '@/components/employee-ng/EditTimeEntry'
import IconButton from '@/components/employee-ng/IconButton'
import TimeEntriesTable from '@/components/employee-ng/TimeEntriesTable'
import { combinedDaysForEntries } from '@/utils'
import { reduce } from 'lodash'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LatestTimeEntries',

  components: {
    AddTimeEntry,
    CustomInput,
    Drawer,
    EditTimeEntry,
    IconButton,
    TimeEntriesTable,
  },

  data() {
    return {
      date: new Date(),
      month: moment().format('YYYY-M'),
      newEntryData: {
        projectId: null,
        tagIds: [],
        task: null,
        genre: 'days',
        amount: null,
      },
      errorMessage: null,
      error: false,
      isModalVisible: false,
      isDrawerOpen: false,
      openFrom: 'left',
      loading: true,
      isOpen: false,
      searchQuery: '',

      editableTimeEntry: null,
    }
  },

  computed: {
    ...mapGetters(['days', 'hours']),

    combinedEntriesLimited() {
      // Return the entries for the last 5 days with entries (not last 5 calendar days)
      const dates = this.combinedEntries.map((entry) => entry.date).slice(0, 5)

      return this.combinedEntries.filter((entry) =>
        dates.some((date) => date === entry.date)
      )
    },

    filteredEntries() {
      const entries =
        this.searchQuery === ''
          ? this.days.concat(this.hours)
          : this.resultQuery

      return entries.sort((a, b) => a.date - b.date)
    },

    combinedEntries() {
      return this.days
        .concat(this.hours)
        .sort((a, b) => (a.date < b.date ? 1 : -1))
    },

    routeEntriesActivated() {
      return this.$route.fullPath === '/entries'
    },

    monthName() {
      return moment(this.month.toString(), 'YYYY-M').format('MMMM')
    },

    resultQuery() {
      if (this.searchQuery !== '') {
        return this.combinedEntries.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(
              (v) =>
                item.msg.toLowerCase().includes(v) ||
                item.project.toLowerCase().includes(v) ||
                item.tags.some((t) => t.name.toLowerCase().includes(v))
            )
        })
      } else {
        return null
      }
    },

    showingCombinedWork() {
      const combined = combinedDaysForEntries(this.filteredEntries)
      return Number(combined).toFixed(2).toString()
    },

    totalCombinedWork() {
      const combined =
        reduce(
          this.combinedEntries,
          (acc, entry) => acc + (entry.days ? entry.days * 7.5 : entry.hours),
          0
        ) / 7.5
      return Number(combined).toFixed(2).toString()
    },
  },

  watch: {
    month(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.loading = true
        this.fetchEntries(this.month).finally(() => (this.loading = false))
      }
    },
  },

  mounted() {
    this.fetchProjects()
    this.fetchEntryTags()
    this.fetchEntries(this.month).finally(() => (this.loading = false))
  },

  methods: {
    ...mapActions([
      'fetchEntries',
      'fetchProjects',
      'fetchEntryTags',
      'deleteEntry',
    ]),

    resetData() {
      this.newEntryData.task = null
      this.newEntryData.tagIds = []
      this.newEntryData.amount = null
      this.newEntryData.date = new Date()
      this.newEntryData.projectId = null
    },

    validateData() {
      if (this.date) {
        if (this.newEntryData.genre === 'days') {
          return this.newEntryData.amount <= 2.0
        } else return this.amount <= 16.0
      } else return false
    },

    handleSubmit(e) {
      if (this.validateData()) {
        const csrfToken = document.getElementsByName('csrfmiddlewaretoken')[0]
          .value
        const data = {
          project_id: this.newEntryData.projectId,
          date: [
            this.date.getFullYear(),
            this.date.getMonth() + 1,
            this.date.getDate(),
          ].join('-'),
          msg: this.newEntryData.task,
          tag_ids: this.newEntryData.tagIds,
        }
        data[this.newEntryData.genre] = this.newEntryData.amount
        const payload = {
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          body: JSON.stringify(data),
        }

        const url = `/api/v1/time-entries/${this.genre}/`

        payload.method = 'POST'
        fetch(url, payload)
          .then((response) => {
            if (response.status === 200) {
              this.showAddSummary = false
            }
          })
          .then(() => {
            this.fetchEntries(this.month)
            this.resetData()
          })
      } else {
        this.error = true
        this.errorMessage = 'Please check input data.'
      }
    },

    handleDeleteEntry(entryId) {
      this.deleteEntry(entryId)
    },

    handleEditEntry(entryId) {
      this.editableTimeEntry =
        this.combinedEntries.find((e) => e.id === entryId) || null
    },

    toggle() {},

    closeAddEntries() {
      if (this.$route.fullPath === '/entries') {
        this.isDrawerOpen = false
        this.$router.push('/')
      }
    },

    openAddEntries() {
      if (this.$route.fullPath !== '/entries') {
        this.isDrawerOpen = true
        this.$router.push('/entries')
      }
    },

    changeMonth(value) {
      this.month = moment(this.month, 'YYYY-M')
        .add(value, 'months')
        .format('YYYY-M')
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  .heading-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      gap: 10px;
    }
  }

  .title-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    .title {
      font-weight: normal;
      border-bottom: 2px solid #000;
      text-transform: uppercase;
      width: auto;
    }

    .title-buttons {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.5em;

      .plus-button {
        transition: 0.1s ease-in-out;
      }

      .cross-button {
        color: #f00;
        rotate: 45deg;
        transition: 0.1s ease-in-out;
      }
    }
  }

  .filter-container {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 720px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  transition: opacity 0s;
}

.button {
  min-width: 90px;
}

.input-field {
  margin: 5px 0;
  height: 40px;
  width: 50%;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  font-family: Montserrat, sans-serif;
}

.add-time-entry-container {
  margin-bottom: 4em;
}
</style>
