<template>
  <div class="table invoice-billing">
    <div v-if="billable">
      <h1 v-if="!searchMonth">
        Calculated prices for {{ projectName }}
        <MonthSelector v-model="month" @input="update()" />
      </h1>

      <div v-else class="comments">
        <a
          :href="`/admin/invoicing/billable/${billable.id}/change/`"
          class="right button"
          target="_blank"
          >Edit Billable
        </a>
        <b>Comments:</b><br />
        {{ billable.comments }}
      </div>

      <table v-if="billable.calculation.special_prices" class="fixed-table">
        <tr>
          <th class="left">Special prices</th>
          <th class="left">Days done</th>
          <th class="left">Daily price</th>
          <th class="left">Hours done</th>
          <th class="left">Hourly price</th>
          <th class="right">Daily and Hourly Total</th>
        </tr>
        <tr
          v-for="(sp, index) in billable.calculation.special_prices"
          :key="index"
        >
          <td class="left">
            {{ sp.employee.name }}
          </td>
          <td class="left">
            {{ sp.days_sum }}
          </td>
          <td class="left">{{ formatCurrency(sp.daily_price) }}€</td>
          <td class="left">
            {{ sp.hours_sum }}
          </td>
          <td class="left">{{ formatCurrency(sp.hourly_price) }}€</td>
          <td class="right">
            {{ formatCurrency(sp.days_sum * sp.daily_price) }} +
            {{ formatCurrency(sp.hours_sum * sp.hourly_price) }} =
            {{
              formatCurrency(sp.days_sum * sp.daily_price) +
              formatCurrency(sp.hours_sum * sp.hourly_price)
            }}€
          </td>
        </tr>
      </table>

      <table class="fixed-table">
        <tr>
          <th class="left">Days in total</th>
          <th v-if="non_billable_days" class="left">Non-billables</th>
          <th class="left">Days included in fee</th>
          <th v-if="reimbursed_days_sum > 0" class="left">
            Reimbursed days for month
          </th>
          <th class="left">Days left to bill</th>
          <th class="left">Default price</th>
          <th class="right">Total</th>
        </tr>
        <tr>
          <td class="left">
            {{ days_in_total }}
          </td>
          <td v-if="non_billable_days" class="left">
            {{ non_billable_days }}
          </td>
          <td class="left">
            {{ billable.days_included_in_contract }}
          </td>
          <td v-if="reimbursed_days_sum > 0" class="left">
            {{ reimbursed_days_sum }}
          </td>
          <td class="left">
            {{ billable_days }} - {{ billable.days_included_in_contract }} -
            {{ reimbursed_days_sum }} = <b>{{ days_after_reimbursement }}</b>
          </td>
          <td class="left">{{ formatCurrency(billable.daily_price) }}€</td>
          <td class="right">{{ formatCurrency(days_money) }}€</td>
        </tr>
      </table>
      <div class="overall right-total">
        Total price for days:
        {{ formatCurrency(days_money + special_days_money) }}€
        <button
          v-if="showDays"
          v-show="!searchMonth"
          @click="showDays = !showDays"
        >
          Hide days
        </button>
        <button v-else v-show="!searchMonth" @click="showDays = !showDays">
          Show day entries
        </button>
      </div>

      <table v-if="showDays" class="fixed-table">
        <tr>
          <th class="date">Date</th>
          <th>Name</th>
          <th>Amount</th>
          <th>Task</th>
        </tr>
        <tr v-for="(day, index) in billable.calculation.days" :key="index">
          <td class="date">
            {{ formatDate(day.date) }}
          </td>
          <td>{{ day.employee.name }}</td>
          <td>{{ day.days }}</td>
          <td>{{ day.msg }}</td>
        </tr>
      </table>

      <table class="fixed-table">
        <tr>
          <th class="left">Hours in total</th>
          <th v-if="non_billable_hours" class="left">Non-billables</th>
          <th class="left">Hours included in fee</th>
          <th class="left">Hours to bill</th>
          <th class="left">Hourly price</th>
          <th class="right">Hours total</th>
        </tr>
        <tr>
          <td class="left">
            {{ hours_in_total }}
          </td>
          <td v-if="non_billable_hours" class="left">
            {{ non_billable_hours }}
          </td>
          <td class="left">
            {{ billable.hours_included_in_contract }}
          </td>
          <td class="left">
            {{ billable.calculation.hours_sum }}
          </td>
          <td class="left">{{ formatCurrency(billable.hourly_price) }}€</td>
          <td class="right">{{ formatCurrency(hours_money) }}€</td>
        </tr>
      </table>
      <div class="overall right-total">
        Total price for hours:
        {{ formatCurrency(hours_money + special_hours_money) }}€
        <button
          v-if="showHours"
          v-show="!searchMonth"
          @click="showHours = !showHours"
        >
          Hide hours
        </button>
        <button v-else v-show="!searchMonth" @click="showHours = !showHours">
          Show hour entries
        </button>
      </div>

      <table v-if="showHours" class="fixed-table">
        <tr>
          <th class="date">Date</th>
          <th>Name</th>
          <th>Amount</th>
          <th>Task</th>
        </tr>
        <tr v-for="(hour, index) in billable.calculation.hours" :key="index">
          <td class="date">
            {{ formatDate(hour.date) }}
          </td>
          <td>{{ hour.employee.name }}</td>
          <td>{{ hour.hours }}</td>
          <td>{{ hour.msg }}</td>
        </tr>
      </table>

      <table class="fixed-table">
        <tr>
          <th class="right-total">Monthly fees</th>
        </tr>
        <tr>
          <td class="right-total">
            {{ formatCurrency(billable.monthly_fee) }}€
          </td>
        </tr>
      </table>
      <div class="overall right-total">
        Total price for {{ searchMonth || month }}:
        {{
          formatCurrency(
            hours_money +
              special_hours_money +
              days_money +
              special_days_money +
              parseFloat(billable.monthly_fee)
          )
        }}€
      </div>
    </div>

    <div v-else class="comments">
      <b>There is no Billable associated with this project.</b>
      <a class="button" href="/admin/invoicing/billable/add/" target="_blank"
        >Add Billable
      </a>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

import { log } from '../../utils.js'
import MonthSelector from '../MonthSelector.vue'

export default {
  components: {
    MonthSelector,
  },
  props: {
    projectName: {
      type: String,
      default: '',
    },

    billableId: {
      type: [String, Number],
      default: null,
    },

    projectId: {
      // alternative lookup for billableId
      type: [String, Number],
      default: null,
    },

    searchMonth: {
      // Component won't show month selector, and entries of passed month will be shown only.
      type: String,
      default: '',
    },
  },

  data() {
    return {
      showDays: false,
      showHours: false,
      month: moment().subtract(1, 'months').format('YYYY-M'),
      billable: {
        id: '',
        days: [],
        hours: [],
        hourly_price: 0,
        daily_price: 0,
        monthly_fee: 0,
        commends: '',
        calculation: {
          days_sum: 0,
          hours_sum: 0,
          hours: [],
          days: [],
          special_prices: [],
        },
      },
    }
  },

  computed: {
    days_money() {
      return this.billable.calculation.days_sum * this.billable.daily_price
    },
    special_days_money() {
      return _.reduce(
        this.billable.calculation.special_prices,
        (col, o) => col + parseFloat(o.days_sum) * parseFloat(o.daily_price),
        0
      )
    },
    hours_money() {
      return this.billable.calculation.hours_sum * this.billable.hourly_price
    },
    special_hours_money() {
      return _.reduce(
        this.billable.calculation.special_prices,
        (col, o) => col + parseFloat(o.hours_sum) * parseFloat(o.hourly_price),
        0
      )
    },

    days_in_total() {
      return _.reduce(
        this.billable.calculation.billable_days,
        (col, o) => col + parseFloat(o.days),
        this.non_billable_days
      )
    },

    hours_in_total() {
      return _.reduce(
        this.billable.calculation.billable_hours,
        (col, o) => col + parseFloat(o.hours),
        this.non_billable_hours
      )
    },

    billable_days() {
      return _.reduce(
        this.billable.calculation.billable_days,
        (col, o) => col + parseFloat(o.days),
        0
      )
    },

    non_billable_days() {
      return _.reduce(
        this.billable.calculation.non_billable_days,
        (col, o) => col + parseFloat(o.days),
        0
      )
    },

    billable_hours() {
      return _.reduce(
        this.billable.calculation.billable_hours,
        (col, o) => col + parseFloat(o.hours),
        0
      )
    },

    non_billable_hours() {
      return _.reduce(
        this.billable.calculation.non_billable_hours,
        (col, o) => col + parseFloat(o.hours),
        0
      )
    },

    fixed_fee() {
      return this.billable.monthly_fee
    },

    reimbursed_days_sum() {
      return _.reduce(
        this.billable.reimbursed_days,
        (col, rd) => {
          const forThisMonth =
            moment(rd.month, 'YYYY-MM-DD').format('YYYY-M') === this.month
              ? parseFloat(rd.days)
              : 0
          return col + forThisMonth
        },
        0
      )
    },

    days_after_reimbursement() {
      return this.billable_days - this.reimbursed_days_sum
    },
  },

  mounted() {
    log('BillableListing.mounted()', this.billableId)
    this.update()
  },

  methods: {
    formatCurrency(moneys) {
      return Math.round(moneys * 100) / 100
    },

    update() {
      const lookup = this.projectId
        ? `?project=${this.projectId}&&`
        : `${this.billableId}/?`
      fetch(
        `/api/v1/invoicing/billables/${lookup}m=${
          this.searchMonth || this.month
        }`,
        { credentials: 'same-origin' }
      ).then((response) =>
        response
          .json()
          .then((data) => (this.billable = this.projectId ? data[0] : data))
      )
    },
    formatDate(date) {
      if (date) return moment(date).format('DD.MM.YYYY - HH:MM')
      return '-'
    },
  },
}
</script>

<style lang="scss">
@import '../frontend/src/assets/scss/variables';

.invoice-billing {
  .comments {
    background: $light-grey;
    white-space: pre-line;
    padding: 20px;
    font-size: 16px;
    margin: 40px;
    display: flow-root;
  }

  .fixed-table {
    table-layout: fixed;

    .right {
      text-align: right;
      padding-right: 5px;
      font-size: 14px;
    }
  }

  .right-total {
    font-weight: bold;
    text-align: right !important;
    font-size: 14px;
    padding-right: 50px;
  }

  .button {
    font-size: 14px;
    float: right;
    padding: 10px;
    background: #79aec8;
    border-radius: 0.1;
    text-decoration: none;
  }
}
</style>
