<template>
  <div class="groupable-table">
    <div v-if="hideGrouping !== true" class="group-by">
      <label>Group by: </label>
      <button :class="{ active: groupByKey === null }" @click="groupBy(null)">None</button>
      <button
        v-for="heading in tableHeadings"
        :key="heading"
        :class="{ active: groupByKey === heading }"
        @click="groupBy(heading)"
      >
        {{ translate(heading) }}
      </button>
    </div>
    <table v-if="data.length > 0" class="full-width nth-coloring">
      <tr>
        <th
          v-for="heading in tableHeadings"
          :key="heading"
          :class="{
            active: orderByKey === heading,
            up: orderDirection === true,
            down: orderDirection === false,
          }"
          @click="orderBy(heading) && reverseOrderDirection()"
        >
          {{ translate(heading) }}
          <span class="up">▴</span>
          <span class="down">▾</span>
        </th>
      </tr>
      <tr v-for="row in orderedData" :key="row.id">
        <td v-for="(value, key) in row" :key="key">
          <template v-if="value && value._className === 'AggregatedNumber'">
            {{ value.sum }} (avg: {{ value.mean }})
          </template>
          <template v-else-if="Array.isArray(value)">
            {{ value.join(', ') }}
          </template>
          <template v-else-if="typeof value === 'boolean'">
            <span v-if="value" class="yes">yes</span>
            <span v-else class="no">no</span>
          </template>
          <template v-else> {{ value }} </template>
        </td>
      </tr>
      <tr v-if="orderedData.length === 0">
        <td>No data available</td>
      </tr>
    </table>
    <div v-else class="no-data">No data available</div>
  </div>
</template>

<script>
import _ from 'lodash'

class AggregatedNumber {
  constructor(listOfValues) {
    this._className = 'AggregatedNumber'
    this.sum = Math.round(_.sum(listOfValues) * 100) / 100
    this.mean = Math.round(_.mean(listOfValues) * 100) / 100
  }
}

export default {
  props: {
    // Array of Objects
    data: {
      type: Array,
      default: () => [],
    },
    // key=heading/key in values, value=string that should be shown
    translations: {
      type: Object,
      default: Object,
    },
    hideGrouping: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return { groupByKey: null, orderByKey: null, orderDirection: true }
  },

  computed: {
    tableHeadings() {
      return this.groupedData.length > 0 ? Object.keys(this.groupedData[0]) : []
    },

    formattedData() {
      return this.groupedData.map((row) => {
        return this.tableHeadings.reduce((col, heading) => {
          if (typeof row[heading] === 'number') {
            col[heading] = Math.round(row[heading] * 100) / 100
          } else if (typeof row[heading] === 'string') {
            col[heading] = row[heading].slice(0, 100)
          } else {
            col[heading] = row[heading]
          }
          return col
        }, {})
      })
    },

    groupedData() {
      if (this.groupByKey !== null) {
        // group the data by the key given to a 2-dimensional array
        const grouped = _.groupBy(this.data, this.groupByKey)
        // reduce back to 1-dimensional by first collecting the values to arrays
        // aggregating those up and forming a new single aggregated value
        return _.reduce(
          grouped,
          (col, rows) => {
            const aggregatedByKey = _.reduce(
              rows,
              (rowCol, row) => {
                _.forEach(row, (value, key) => {
                  if (!Object.prototype.hasOwnProperty.call(rowCol, key)) {
                    rowCol[key] = []
                  }
                  if (typeof value === 'string') {
                    if (!rowCol[key].includes(value)) {
                      rowCol[key].push(value)
                    }
                  } else {
                    rowCol[key].push(value)
                  }
                })
                return rowCol
              },
              {}
            )

            const aggregated = _.reduce(
              aggregatedByKey,
              (col, values, key) => {
                if (typeof values[0] === 'number' && key !== 'id') {
                  col[key] = new AggregatedNumber(values)
                } else {
                  col[key] = values
                    .filter((v) => _.trim(v))
                    .join(', ')
                    .slice(0, 100)
                }
                return col
              },
              {}
            )
            col.push(aggregated)
            return col
          },
          []
        )
      }
      return this.data
    },

    // WIP.. would need some more sense in how we handle numbers
    // step further: now we have AggregatedNumber, but still need to check
    // how we define the type of a column (just pick the first value?)
    orderedData() {
      let rows = this.groupedData
      if (this.orderByKey !== null) {
        rows = _.orderBy(this.groupedData, (row) => {
          const val = row[this.orderByKey]
          if (val.constructor.name === 'AggregatedNumber') {
            return val.sum
          }
          if (typeof val === 'number') {
            return val
          }
          return val
        })
      }
      return this.orderDirection === true ? rows : rows.reverse()
    },
  },

  mounted() {},

  methods: {
    translate(key) {
      return Object.prototype.hasOwnProperty.call(this.translations, key)
        ? this.translation[key]
        : key
    },

    groupBy(key) {
      this.groupByKey = key
    },

    orderBy(key) {
      console.log('orderby', key)
      this.orderByKey = key
      return true
    },

    reverseOrderDirection() {
      this.orderDirection = !this.orderDirection
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/scss/variables';

.groupable-table {
  padding: 20px 0;
  display: block;

  .group-by {
    background: $light-grey;
    padding: 2px 10px;
    font-size: 0.8rem;

    label {
      margin-right: 10px;
      text-transform: uppercase;
    }

    button {
      margin-right: 10px;
      background: none;
      color: $dark-grey;
      text-transform: cavpitalize;
      text-decoration: underline dotted;
      text-underline-offset: 3px;

      &.active {
        text-decoration: underline solid;
        color: $black;
      }
    }
  }

  .no-data {
    background: $light-grey;
    width: 100%;
    padding: 10px;
    text-align: center;
  }

  table {
    th {
      text-transform: capitalize;
      cursor: pointer;

      span {
        opacity: 0.5;
      }

      &.active {
        &.up {
          .up {
            opacity: 1;
          }
        }

        &.down {
          .down {
            opacity: 1;
          }
        }
      }
    }

    td {
      .yes,
      .no {
        font-size: 0.8rem;
        color: white;
        display: inline-block;
        padding: 1px 2px;
      }

      .yes {
        background-color: $green;
      }

      .no {
        background-color: $red;
      }
    }
  }
}
</style>
