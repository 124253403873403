<template>
  <div class="modal-component">
    <div class="backdrop" />

    <div class="modal">
      <IconButton
        class="close-button"
        icon="plus-circle"
        @click="$emit('close')"
      />
      <slot />
    </div>
  </div>
</template>

<script>
import IconButton from '@/components/employee-ng/IconButton'

export default {
  components: {
    IconButton,
  },
}
</script>

<style lang="scss">
@import 'src/assets/scss/variables';

.modal-component {
  // if these are not applied here, the Vue transition will appear to jump
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100vh;
  z-index: +999;

  .backdrop {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    background: rgba($light-grey, 0.8);
  }

  .modal {
    padding: 80px;
    box-sizing: border-box;
    position: absolute;
    top: 5vh;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 10%);
    border-radius: 2px;
    width: 80%;
    min-height: 100px;
    background: white;
    margin-bottom: 40px;

    .close-button {
      position: absolute;
      top: 2em;
      right: 2em;
      transform: rotateZ(45deg);
    }

    .bottom-bar {
      position: absolute;
      bottom: 20px;
      right: 20px;
      padding-top: 4px;
      width: 100%;

      button {
        margin: 0 0 0 20px;
        border: 0;
        border-radius: 3px;
        padding: 12px 16px;
      }

      .cancel {
        background: $red;
      }
    }

    @media screen and (max-width: 600px) {
      width: 100%;
      padding: 20px 20px 80px;
      top: 2vh;
    }
  }
}
</style>
