<template>
  <div class="project-allocations">
    <div class="history">
      <h1>
        Done work summary {{ monthHistory }}
        <MonthSelector v-model="monthHistory" :months-in-future="0" />
      </h1>

      <div class="stats">
        <day-per-day-employees :month="monthHistory" />
      </div>
    </div>
    <div class="future">
      <h1>
        Allocations for {{ monthFuture }}
        <MonthSelector
          v-model="monthFuture"
          :months-in-future="3"
          @input="updateFuture()"
        />
      </h1>

      <h2>
        Allocation status: <u>{{ currentlyAllocated }}</u> days allocated out of
        <u>{{ availabilities.max_days }}</u> days in
        {{ monthName }}
      </h2>

      <div id="progress">
        <div :style="{ width: allocationPercentage + '%' }">
          {{ allocationPercentage }}%
        </div>
      </div>

      <table>
        <thead>
          <th>Type</th>
          <th>Project</th>
          <th>Days per month / in total</th>
          <th>Duration</th>
        </thead>
        <tr v-for="alloc in combinedAllocations" :key="alloc.type + alloc.id">
          <td>{{ alloc.type }}</td>
          <td>
            {{ alloc.project.name }}
          </td>
          <td>
            {{ alloc.days_per_month || 0 }} / {{ alloc.days_in_total || 0 }}
          </td>
          <td>{{ alloc.start_month }} to {{ alloc.end_month }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import MonthSelector from '../MonthSelector.vue'
import DayPerDayEmployees from './DayPerDayEmployees.vue'

export default {
  components: {
    MonthSelector,
    DayPerDayEmployees,
  },
  props: [],

  data() {
    return {
      monthHistory: moment().format('YYYY-MM'),
      monthFuture: moment().format('YYYY-MM'),
      employees: [],
      allocations: { monthly: [], project: [] },
      availabilities: {
        max_days: 0,
        employees_contributing: 0,
        average_max_days: 0,
      },
      timeEntries: [],
      colors: {},
    }
  },

  computed: {
    currentlyAllocated() {
      return (
        this.allocations.project.reduce(
          (col, alloc) => col + parseFloat(alloc.days_per_month),
          0
        ) +
        this.allocations.monthly.reduce(
          (col, alloc) => col + parseFloat(alloc.days_per_month),
          0
        )
      )
    },

    allocationPercentage() {
      return (
        (this.currentlyAllocated / this.availabilities.max_days) *
        100
      ).toFixed(2)
    },

    combinedAllocations() {
      return [].concat(
        this.allocations.monthly.map((alloc) => {
          alloc.type = 'monthly'
          return alloc
        }),
        this.allocations.project.map((alloc) => {
          alloc.type = 'project'
          return alloc
        })
      )
    },

    monthName() {
      return moment(this.monthFuture, 'YYYY-MM').format('MMMM')
    },

    timeEntriesByEmployee() {
      return this.timeEntries.reduce((col, entry) => {
        if (col[entry.employee_id] === undefined) {
          col[entry.employee_id] = []
        }
        col[entry.employee_id].push(entry)
        return col
      }, {})
    },
  },

  mounted() {
    console.log('ProjectAllocations.mounted()')
    this.fetchCommon()
    this.updateFuture()
  },

  methods: {
    fetchCommon() {
      fetch('/api/v1/employees/', {
        credentials: 'same-origin',
      }).then((response) =>
        response.json().then((data) => {
          this.employees = data
        })
      )
    },
    fetchFuture() {
      fetch(`/api/v1/planning/allocations/?month=${this.monthFuture}`, {
        credentials: 'same-origin',
      }).then((response) =>
        response.json().then((data) => {
          this.allocations = data
        })
      )
      fetch(`/api/v1/planning/availabilities/?month=${this.monthFuture}`, {
        credentials: 'same-origin',
      }).then((response) =>
        response.json().then((data) => {
          this.availabilities = data
        })
      )
    },

    updateFuture() {
      this.fetchFuture()
    },

    color(color) {
      // TODO: Move this to utils
      if (!this.colors.color) {
        const hexcolor = color.replace('#', '')
        const r = parseInt(hexcolor.substr(0, 2), 16)
        const g = parseInt(hexcolor.substr(2, 2), 16)
        const b = parseInt(hexcolor.substr(4, 2), 16)
        const yiq = (r * 299 + g * 587 + b * 114) / 1000
        this.colors[color] = yiq >= 128 ? 'black' : 'white'
      }
      return this.colors[color]
    },

    getEmployee(id) {
      id = parseInt(id)
      const matches = this.employees.filter((emp) => emp.id === id)
      return matches.length > 0 ? matches[0] : null
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/scss/variables';

.project-allocations {
  display: flex;
  flex-direction: column;

  #progress {
    min-width: 300px;
    border: 1px solid rgba(162, 162, 162, 69%);
    margin: 0 auto 20px;

    div {
      height: 28px;
      font-size: 20px;
      text-align: center;
      background: #76c42e;
      color: rgba(0, 0, 0, 70%);
    }
  }

  table {
    width: 100%;
  }

  .history,
  .future {
    flex: 1;
    padding: 20px;
  }

  .history {
    background: #fff6f6;
  }

  .future {
    background: #fafffa;
  }
}
</style>
