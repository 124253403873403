<template>
  <div class="project-selector">
    <select v-model="value" @change="update()">
      <option value="">All</option>
      <option v-for="(d, index) in projects" :key="index" :value="d.id">
        {{ d.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: [],

  data() {
    return { value: null, projects: [] }
  },

  computed: {},

  mounted() {
    console.log('ProjectSelector.mounted()')
    fetch('/api/v1/projects/', { credentials: 'same-origin' })
      .then((response) => response.json())
      .then((data) => (this.projects = data))
  },

  methods: {
    update() {
      this.$emit('input', this.value)
    },
  },
}
</script>
