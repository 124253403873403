var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.kudos.length > 0)?_c('table',{staticClass:"kudos-table table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("DATE")]),_c('th',[_vm._v(_vm._s(_vm.given ? 'TO' : _vm.received ? 'FROM' : '?'))]),_c('th',[_vm._v("MESSAGE")])])]),_c('tbody',[_vm._l((_vm.kudos),function(kudo,kudo_index){return [_c('tr',{key:kudo.id,class:{
          row: true,
          even: !!!(kudo_index % 2),
          odd: !!(kudo_index % 2),
        },on:{"click":function($event){return _vm.toggleRow(kudo.id)}}},[_c('td',[_vm._v(_vm._s(_vm.date_formatter(kudo.created_at)))]),_c('td',[(_vm.received)?_c('div',{staticClass:"from"},[_c('img',{attrs:{"src":kudo.giver.photo,"alt":kudo.giver.name}}),_c('p',[_vm._v(_vm._s(kudo.giver.name))])]):(_vm.given)?_vm._l((kudo.receivers),function(receiver,receiver_index){return _c('div',{key:receiver_index,staticClass:"from"},[_c('img',{attrs:{"src":receiver.photo,"alt":receiver.name}}),_c('p',[_vm._v(_vm._s(receiver.name))])])}):_vm._e()],2),_c('td',[_vm._v(_vm._s(kudo.description))])]),(_vm.expanded.includes(kudo.id) && kudo.skills.length > 0)?_c('tr',{key:kudo.id,class:{
          row: true,
          even: !!!(kudo_index % 2),
          odd: !!(kudo_index % 2),
        },on:{"click":function($event){return _vm.toggleRow(kudo.id)}}},[_c('td',{staticClass:"expanded-row",attrs:{"colspan":"3"}},[_c('p',{staticClass:"row-divider"},[_vm._v("TEAM VALUES 🙌")]),_vm._l((kudo.team_values),function(value,j){return _c('p',{key:j},[_vm._v(" 🚀 "+_vm._s(value.text)+" ")])})],2)]):_vm._e(),(_vm.expanded.includes(kudo.id) && kudo.skills.length > 0)?_c('tr',{key:kudo.id,class:{
          row: true,
          even: !!!(kudo_index % 2),
          odd: !!(kudo_index % 2),
        },on:{"click":function($event){return _vm.toggleRow(kudo.id)}}},[_c('td',{staticClass:"expanded-row",attrs:{"colspan":"3"}},[_c('p',{staticClass:"row-divider"},[_vm._v("SKILLS 🧑‍💻")]),_vm._l((kudo.skills),function(skill,k){return _c('p',{key:k},[_vm._v(" 🌟 "+_vm._s(skill.text)+" ")])})],2)]):_vm._e()]})],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }