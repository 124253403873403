<template>
  <div class="monthly-project-distribution">
    <div class="header">
      <h1>
        Monthly project distribution
        <MonthSelector v-model="month" @input="update()" />
      </h1>
    </div>
    <div v-if="graphData" class="data">
      <PieChart
        :colors="colors"
        :labels="labels"
        :series="series"
        height="40vh"
      />
      <table>
        <tr>
          <th>Project</th>
          <th>Combined days spent</th>
        </tr>
        <tr v-for="(p, index) in graphData" :key="index">
          <td v-if="p['sum'] > 0">
            {{ p['name'] }}
          </td>
          <td v-if="p['sum'] > 0">
            {{ parseInt(p['sum'] * 100) / 100 }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

import { log } from '../utils.js'
import MonthSelector from './MonthSelector'
import PieChart from './PieChart'

export default {
  components: {
    MonthSelector,
    PieChart,
  },
  props: [],

  data() {
    return {
      values: [],
      month: moment().subtract(1, 'months').format('YYYY-M'),
      chart: null,
      graphData: [],
    }
  },

  computed: {
    series() {
      return _.map(this.graphData, (d) => d.sum)
    },
    colors() {
      return _.map(this.graphData, (d) => d.color)
    },
  },

  mounted() {
    log('MonthlyProjectDistribution.mounted()')
    this.fetchProjects()
  },

  methods: {
    fetchProjects() {
      fetch(`/api/v1/projects/monthly_distribution?m=${this.month}`, {
        credentials: 'same-origin',
      }).then((response) =>
        response.json().then((data) => {
          this.graphData = data
          this.graphData.sort((a, b) => (a.sum > b.sum ? -1 : 1))
        })
      )
    },

    update() {
      log('MonthlyProjectDistribution:update()', this.month)
      this.fetchProjects()
    },
  },
}
</script>

<style lang="scss">
.monthly-project-distribution {
  .data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

    table {
      width: 100%;
    }
  }
}
</style>
