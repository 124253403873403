<template>
  <div class="bucket">
    <div class="fill" :style="fill" />
    <div class="metric">
      <p class="used-allocation">
        {{
          usedAllocation.toFixed ? usedAllocation.toFixed(2) : usedAllocation
        }}
      </p>
      <p class="caption">days this month</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Allocation',

  props: {
    totalAllocation: {
      type: Number,
      required: true,
    },

    usedAllocation: {
      type: Number,
      required: true,
    },
  },

  computed: {
    fill() {
      // If there's no fixed allocation, it will be 0, so safe division
      // and Math.min to avoid going above 100%
      const fillPercentage =
        this.totalAllocation > 0
          ? Math.min((this.usedAllocation / this.totalAllocation) * 100, 100)
          : 0

      return {
        height: `${Math.ceil(fillPercentage)}%`,
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/variables';

.bucket {
  color: black;
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid #eee;
  background-color: #fff;
  position: relative;
  width: 9rem;

  .fill {
    position: absolute;
    width: 100%;
    background-color: $atmos-violet;
    opacity: 0.3;
  }

  .metric {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: auto;
    margin: 1em;

    p {
      font-weight: 700;
      margin: 0;
      padding: 0;
      z-index: 1;

      &.used-allocation {
        font-size: 2.5rem;
      }

      &.caption {
        font-size: 0.8rem;
      }
    }
  }
}
</style>
