<template>
  <div class="table client-view">
    <h1>
      {{ token ? '' : clientName }}
      <MonthSelector v-model="month" @input="fetchData()" />
      <a
        :href="`/api/v1/invoicing/billables/download?client_id=${clientId}&month=${month}&token=${token}&etype=Hours&file_format=pdf`"
        class="button"
      >
        Download PDF (hours)
      </a>
      <a
        :href="`/api/v1/invoicing/billables/download?client_id=${clientId}&month=${month}&token=${token}&etype=Days&file_format=pdf`"
        class="button"
      >
        Download PDF (days)
      </a>
    </h1>
    <div class="top-area">
      <div class="summary">
        <p class="alignleft">
          <b>Days combined:</b>
        </p>
        <p class="alignright">
          {{ daysSum }}
        </p>
        <div style="clear: both" />
        <p class="alignleft">
          <b>Hours combined:</b>
        </p>
        <p class="alignright">
          {{ hoursSum }}
        </p>
        <div style="clear: both" />
        <br />
        <p class="alignleft">
          <b>Total:</b>
        </p>
        <p class="alignright">
          {{ totalSum }}
        </p>
        <div style="clear: both" />
      </div>
      <div class="allocations">
        <ActiveAllocations :client-id="clientId" :month="month" />
      </div>
    </div>

    <div
      v-for="(entry, project, index) in entries"
      :key="index"
      class="project"
    >
      <div id="stuff">
        <div class="options">
          <div class="left-pane">
            <h2>{{ project }}</h2>
            <div class="button-container">
              <button
                v-if="!token"
                class="button"
                @click="toggle($event, `payment_${index}`)"
              >
                Show Billables
              </button>
              <!-- <p>{{getTags(project)}}</p> -->
              <button class="button" @click="toggle($event, `entry_${index}`)">
                Show Entries
              </button>
            </div>
          </div>
          <div class="right-pane">
            <h3>Download PDF for tags:</h3>
            <button
              v-for="tag in getTags(project)"
              :key="tag.id"
              class="button"
              @click="
                getEntriesPerTag(clientId, month, tag, token, (etype = 'Days'))
              "
            >
              {{ tag }} (days)
            </button>
            <button
              v-for="tag in getTags(project)"
              :key="tag.id"
              class="button"
              @click="
                getEntriesPerTag(clientId, month, tag, token, (etype = 'Hours'))
              "
            >
              {{ tag }} (hours)
            </button>
          </div>
        </div>

        <invoicing-billable
          v-if="!token"
          :id="`payment_${index}`"
          :key="`${entry.project_id}_${month}`"
          :project-id="entry.project_id"
          :search-month="month"
          style="display: none"
        />

        <div :id="`entry_${index}`" style="display: none">
          <table class="overview full-width">
            <tr>
              <th class="center" style="width: 50%">Days</th>
              <th class="center" style="width: 50%">Hours</th>
            </tr>
            <tr>
              <td class="center">
                <span v-if="daysSelectedFor(project) > 0"
                  >{{ daysSelectedFor(project) }} /
                </span>
                {{ daysSumFor(project) }}
              </td>
              <td class="center">
                <span v-if="hoursSelectedFor(project) > 0"
                  >{{ hoursSelectedFor(project) }} /
                </span>
                {{ hoursSumFor(project) }}
              </td>
            </tr>
          </table>

          <TimeEntryPicker
            v-model="entry.pickedDays"
            :entries="entry.days.concat(entry.hours)"
            :no-checkbox="true"
            title="Days"
            :month="month"
            @input="entryPicked"
          />

          <h2>Per employee</h2>
          <div class="project">
            <table class="full-width nth-coloring">
              <tr>
                <th />
                <th style="width: 25%">Days</th>
                <th style="width: 25%">Hours</th>
              </tr>
              <tr v-for="(p, emp) in entry.empEntries" :key="emp">
                <td>{{ emp }}</td>
                <td>{{ p.days }}</td>
                <td>{{ p.hours }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

import { log } from '../../utils'
import MonthSelector from '../MonthSelector.vue'
import TimeEntryPicker from '../TimeEntryPicker.vue'
import ActiveAllocations from './ActiveAllocations.vue'
import InvoicingBillable from './InvoicingBillable.vue'

export default {
  components: {
    TimeEntryPicker,
    MonthSelector,
    InvoicingBillable,
    ActiveAllocations,
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },

    clientName: {
      type: String,
      default: '',
    },

    token: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      month: moment().format('YYYY-M'),
      values: {
        days: [],
        hours: [],
      },
    }
  },

  computed: {
    entries() {
      // Combine entriesByProjects and entriesPerEmployeeInProjects!

      Object.keys(this.entriesByProjects).forEach((key) => {
        this.entriesByProjects[key].empEntries =
          this.entriesPerEmployeeInProjects[key]
      })

      return this.entriesByProjects
    },

    entriesByProjects() {
      const byDays = _.reduce(
        this.values.days,
        (col, d) => {
          const newD = Object.assign({}, d, {
            id: 'days-' + d.id,
            amount: d.days,
          })
          if (col[d.project]) {
            col[d.project].days.push(newD)
          } else {
            col[d.project] = {
              days: [newD],
              hours: [],
              pickedDays: [],
              pickedHours: [],
              project_id: d.project_id,
            }
          }
          return col
        },
        {}
      )
      const all = _.reduce(
        this.values.hours,
        (col, d) => {
          const newD = Object.assign({}, d, {
            id: 'hours-' + d.id,
            amount: d.hours,
          })
          if (col[d.project]) {
            col[d.project].hours.push(newD)
          } else {
            col[d.project] = {
              days: [],
              hours: [newD],
              pickedDays: [],
              pickedHours: [],
              project_id: d.project_id,
            }
          }
          return col
        },
        byDays
      )

      return all
    },

    entriesPerEmployeeInProjects() {
      const byDays = _.reduce(
        this.values.days,
        (col, d) => {
          if (!col[d.project]) {
            col[d.project] = {}
          }
          if (!col[d.project][d.employee]) {
            col[d.project][d.employee] = { days: 0, hours: 0 }
          }
          col[d.project][d.employee].days += d.days
          return col
        },
        {}
      )
      const all = _.reduce(
        this.values.hours,
        (col, d) => {
          if (!col[d.project]) {
            col[d.project] = {}
          }
          if (!col[d.project][d.employee]) {
            col[d.project][d.employee] = { days: 0, hours: 0 }
          }
          col[d.project][d.employee].hours += d.hours
          return col
        },
        byDays
      )
      return all
    },
    daysSum() {
      return this.values.days
        .map((day) => day.days)
        .reduce((prev, next) => prev + next, 0)
    },
    hoursSum() {
      return this.values.hours
        .map((hour) => hour.hours)
        .reduce((prev, next) => prev + next, 0)
    },
    totalSum() {
      return (this.daysSum + this.hoursSum / 7.5).toFixed(2)
    },
  },

  mounted() {
    log('ClientView.mounted()')
    this.fetchData()
  },

  methods: {
    fetchData() {
      const url = this.token
        ? `/token-api/${this.token}/time-entries?m=${this.month}`
        : `/api/v1/time-entries/client/${this.clientId}?m=${this.month}`

      fetch(url, { credentials: 'same-origin' }).then((response) =>
        response.json().then((data) => {
          this.values = Object.assign({}, data)
        })
      )
    },

    entryPicked(e) {
      // a bit of a crutch here as updates are not automatically found for the sums changes
      this.$forceUpdate()
    },

    daysSumFor(project) {
      return _.reduce(
        this.entriesByProjects[project].days,
        (col, d) => {
          return col + d.days
        },
        0
      )
    },

    hoursSumFor(project) {
      return _.reduce(
        this.entriesByProjects[project].hours,
        (col, d) => {
          return col + d.hours
        },
        0
      )
    },

    daysSelectedFor(project) {
      console.log('days selected for', this.entriesByProjects)
      return _.reduce(
        this.entriesByProjects[project].pickedDays,
        (col, d) => {
          return col + d.days
        },
        0
      )
    },

    hoursSelectedFor(project) {
      return _.reduce(
        this.entriesByProjects[project].pickedHours,
        (col, d) => {
          return col + d.hours
        },
        0
      )
    },

    toggle(e, id) {
      const element = document.getElementById(id)
      if (e.currentTarget.innerText.includes('Show')) {
        e.currentTarget.innerText = e.currentTarget.innerText.replace(
          'Show',
          'Hide'
        )
        element.style.display = 'block'
      } else {
        e.currentTarget.innerText = e.currentTarget.innerText.replace(
          'Hide',
          'Show'
        )
        element.style.display = 'none'
      }
    },
    getTags(project) {
      const data = JSON.parse(JSON.stringify(this.entriesByProjects))
      const proj = data[project]
      const allTags = []

      Object.keys(proj).forEach((e) => {
        if (e === 'days') {
          const days = proj[e]
          days.forEach((e) => {
            if (e.tags.length > 0) {
              const tags = e.tags
              Object.keys(tags).forEach((e) => {
                allTags.push(tags[e].name)
              })
            }
          })
        } else if (e === 'hours') {
          const hours = proj[e]
          hours.forEach((e) => {
            if (e.tags.length > 0) {
              const tags = e.tags
              Object.keys(tags).forEach((e) => {
                allTags.push(tags[e].name)
              })
            }
          })
        }
      })
      if (allTags.length > 0) {
        return allTags.filter((item, i, ar) => ar.indexOf(item) === i)
      } else {
        return allTags
      }
    },
    getEntriesPerTag(client, month, tag, token, etype) {
      const url = `/api/v1/invoicing/billables/download?client_id=${client}&month=${month}&tag=${tag}&token=${token}&file_format=pdf&etype=${etype}`
      fetch(url, { credentials: 'same-origin' })
        .then((response) => response.blob())
        .then((blob) => {
          const fileURL = window.URL.createObjectURL(blob)
          const fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute(
            'download',
            `${this.clientName}_${tag}_${month}.pdf`
          )
          document.body.appendChild(fileLink)
          fileLink.click()
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../frontend/src/assets/scss/variables';

.client-view {
  .button {
    font-size: 14px;
    float: right;
    padding: 10px;
    background: #79aec8;
    border-radius: 4px;
    color: white;
    text-decoration: none;
    text-transform: none;
    margin: 5px;

    &:hover {
      background: #5886c8;
    }
  }

  .top-area {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    display: flex;
    border: 1px solid #eee;

    .allocations {
      width: 100%;
      padding: 20px;
    }

    .summary {
      margin: 30px 0 30px 20px;
      background: $grey-fade;
      padding: 20px 35px;
      font-size: 16px;
      width: 250px;
      max-height: 170px;
      box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 40%);

      .alignleft {
        float: left;
      }

      .alignright {
        float: right;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;

      .allocations {
        padding: 0;
      }
    }
  }

  .options {
    // display: inline-flex;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 20px 0;
  }

  .right-pane {
    display: flex;
  }
}
</style>
