<template>
  <div class="accordion">
    <header :class="{ open: open }" @click="open = !open">
      <div>
        <span>▴</span>
        {{ title }}
      </div>
      <div v-if="extraContentHtml" class="extra-content" v-html="extraContentHtml" />
    </header>
    <div class="accordion-content" :class="{ open: open }">
      <slot>Accordion content</slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    // extra content on the right side
    extraContentHtml: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      open: this.initialOpen === true,
    }
  },

  computed: {},

  mounted() {},

  methods: {},
}
</script>

<style lang="scss">
@import '../../assets/scss/variables';

.accordion {
  header {
    padding: 15px 20px 15px 10px;
    background: $orange;
    border: 1px solid $lighter-grey;
    margin-bottom: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div:first-child span {
      display: inline-block;
      transform: rotate(90deg);
      transform-origin: 50% 50%;
      margin-right: 20px;
      transition: transform 200ms;
    }

    div {
      flex: 1;
    }

    div:last-child {
      text-align: right;
    }

    &.open {
      div:first-child span {
        transform: rotate(180deg);
      }
    }
  }

  .accordion-content {
    height: 0;
    overflow: hidden;
    border: 1px solid $lighter-grey;

    &.open {
      padding: 10px;
      height: auto;
    }
  }
}
</style>
