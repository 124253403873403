<template>
  <div class="invoicing-employee-box">
    <Accordion
      :title="employeeData['name']"
      :extra-content-html="`<span>${employeeData['billing_sum']} €</span> <span>${employeeData['days_sum']} d</span>`"
    >
      <h3>Time entries</h3>
      <GroupableTable :data="employeeData['entries']" />

      <h3>Total billing</h3>
      <GroupableTable :data="employeeData['fees']" :hide-grouping="true" />
    </Accordion>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

import Accordion from './Accordion.vue'
import GroupableTable from './GroupableTable.vue'

export default {
  components: { GroupableTable, Accordion },
  props: {
    employeeData: {
      type: Object,
      required: true,
    },
    month: {
      type: DateTime,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    tableHeadings() {
      return Object.keys(this.employeeData)
    },
    monthName() {
      return this.month.toFormat('MMMM, yyyy')
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/scss/variables';

.invoicing-employee-box {
  h3 {
    text-transform: uppercase;
    font-family: $font_title;
  }

  .extra-content {
    display: flex;
    justify-content: end;

    span {
      width: 100%;
      max-width: 5rem;

      &:first-child {
        border-right: 1px solid $light-grey;
        padding-right: 20px;
      }
    }
  }

  .groupable-table {
    padding-top: 5px;
  }

  .bottom {
    display: flex;

    > div {
      flex: 1;
    }

    .notes {
      background: $light-grey;
      padding: 15px;
      min-height: 30px;
    }

    .actions {
      display: flex;
      justify-content: end;
      align-items: center;
    }
  }
}
</style>
