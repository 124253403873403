import _ from 'lodash'
import moment from 'moment'

// Paul Irish' shorthand log (converted to use apply)
function log() {
  log.history = log.history || []
  log.history.push(arguments)
  if (window.console && console.log.apply) {
    console.log.apply(console, arguments)
  }
}

const projectStatusColors = {
  RED: '#da0000',
  YELLOW: '#e7e01e',
  GREEN: '#16a40c',
  GREY: '#858585',
}

const projectStatusHelp = {
  YELLOW: 'Needs some attention or update',
  GREEN: 'All good, continue as usual',
  RED: 'Needs urgent attention, fixes',
  GREY: 'Nothing going on at the moment',
}

// Basic handler for fetch() errors
function handleFetchErrors(response) {
  if (!response.ok) {
    throw Error(`${response.statusText}`)
  }
  return response
}

function daysHours(days = 0, hours = 0) {
  let formatted = ''
  if (days > 0 && days <= 1) formatted = `${days} day`
  else if (days > 1) formatted += `${days} days`
  if (days > 0 && hours > 0) formatted += ' and '
  if (hours > 0 && hours <= 1) formatted += `${hours} hour`
  else if (hours > 1) formatted += `${hours} hours`

  return formatted
}

function combinedDaysForEntries(entries) {
  return (
    _.reduce(entries, (col, entry) => col + (entry.days ? entry.days * 7.5 : entry.hours), 0) /
    7.5
  )
}

function dateToString(date) {
  // Return the date formatted as YYYY-MM-DD
  return moment(date).format('YYYY-MM-DD')
}

function findGetParameter(parameterName) {
  let result = null
  let tmp = []
  location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=')
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
    })
  return result
}

export {
  log,
  handleFetchErrors,
  projectStatusHelp,
  projectStatusColors,
  daysHours,
  combinedDaysForEntries,
  dateToString,
  findGetParameter,
}
