<template>
  <form class="time-entry-form ng-form" @submit.prevent="submit">
    <h3 class="sub-title">
      <span v-if="timeEntry">Edit</span>
      <span v-else>Add New</span>
      Time Entry
    </h3>

    <div class="field-group field-project">
      <label> Project </label>

      <Multiselect
        v-model="selectedProject"
        placeholder="Choose project"
        label="name"
        track-by="name"
        :allow-empty="true"
        :multiple="false"
        :options="projects"
      />
    </div>

    <div class="field-group field-tags">
      <label> Tags </label>

      <Multiselect
        v-model="selectedTags"
        placeholder="Pick some"
        label="name"
        track-by="name"
        :allow-empty="true"
        :multiple="true"
        :options="tags"
      />
    </div>

    <div class="field-group field-message">
      <label> Message </label>

      <input
        v-model="message"
        placeholder="Write a quick status update here"
        class="input-field"
      />
    </div>

    <div class="field-group field-date">
      <label> Date </label>

      <DatePicker
        v-model="date"
        :monday-first="true"
        :placeholder="'Date'"
        name="date"
        :use-utc="true"
      />
    </div>

    <div class="field-group field-amount">
      <label> Amount </label>

      <input v-model="rawAmount" placeholder="Amount" class="input-field" />
      <span class="help-text"
        >Use "h" for hours and "d" for days, e.g. "0.5d" or "3h"</span
      >
    </div>

    <div class="actions">
      <div class="error-message">
        <p v-if="error" class="error">
          Are you sure about {{ amount }} {{ units }}? Please check your entry!
        </p>
      </div>

      <button class="button" :disabled="isSubmitDisabled" type="submit">
        <span v-if="timeEntry">Update</span>
        <span v-else>Create</span>
        entry
      </button>

      <button v-if="timeEntry" class="button" @click="$emit('dismiss')">
        Cancel
      </button>
    </div>
  </form>
</template>

<script>
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vuejs-datepicker'
import { mapGetters } from 'vuex'

const rawValueRegex = /\s*([0-9]+.?[0-9]*)([h|d])/

export default {
  name: 'TimeEntryForm',

  components: {
    DatePicker,
    Multiselect,
  },

  props: {
    timeEntry: {
      type: Object,
      default: null,
    },
  },

  emits: ['dismiss', 'submit'],

  data() {
    return {
      rawAmount: '',
      amount: null,
      date: new Date(),
      error: false,
      message: '',
      selectedProject: (this.timeEntry && this.timeEntry.project_id) || '',
      selectedTags: ((this.timeEntry && this.timeEntry.tags) || []).map(
        (t) => t.id
      ),
    }
  },

  computed: {
    ...mapGetters(['projects', 'tags']),

    isSubmitDisabled() {
      return (
        this.message === '' || !this.selectedProject || this.amount === null
      )
    },
  },

  watch: {
    rawAmount(newVal, oldVal) {
      const matches = newVal.match(rawValueRegex)
      console.log(newVal, matches)
      if (matches !== null && matches.length === 3) {
        const [, amount, type] = matches
        this.amount = amount
        if (type === 'd') {
          this.units = 'days'
        } else if (type === 'h') {
          this.units = 'hours'
        } else {
          alert('error that should not happen')
        }
      } else {
        this.amount = null
      }
    },

    amount(newVal, oldVal) {
      this.error = oldVal === newVal
    },
  },

  mounted() {
    if (!this.timeEntry) {
      return
    }

    console.log('open existing', this.timeEntry)
    this.amount = this.timeEntry.amount
    this.date = new Date(this.timeEntry.date)
    this.message = this.timeEntry.msg
    this.rawAmount = `${this.amount}${
      this.timeEntry.units === 'days' ? 'd' : 'h'
    }`

    this.selectedProject = this.projects.find(
      (proj) => proj.id === this.timeEntry.project_id
    )

    const tagIds = (this.timeEntry.tags || []).map((tag) => tag.id)
    this.selectedTags = this.tags.filter((tag) => tagIds.includes(tag.id))
  },

  methods: {
    clear() {
      this.amount = ''
      this.date = new Date()
      this.message = ''
      this.selectedProject = null
      this.selected = 'days'
      this.selectedTags = []
      this.rawAmount = ''
    },

    submit() {
      if (!this.validate()) {
        this.error = true
        return
      }

      const projectKey = this.timeEntry ? 'project' : 'project_id'
      const tagsKey = this.timeEntry ? 'tags' : 'tag_ids'

      const payload = {
        id: this.timeEntry && this.timeEntry.entryId,
        units: this.units,
        entry: {
          msg: this.message,
          date: moment(this.date).format('YYYY-MM-DD'),
          [projectKey]: this.selectedProject.id,
          [tagsKey]: this.timeEntry
            ? this.selectedTags.map((tag) => tag.id)
            : this.selectedTags,
          [this.units]: this.amount,
        },
      }

      this.$emit('submit', payload)
      this.clear()
    },

    validate() {
      if (!this.date) {
        return false
      }

      if (this.selected === 'days') {
        return this.amount <= 2.0
      }

      return this.amount <= 16.0
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/ng-forms';
@import 'vue-multiselect/dist/vue-multiselect.min.css';

.time-entry-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1em;
  row-gap: 0.5em;

  & > * {
    grid-column: 1 / -1;
  }

  .sub-title {
    font-size: 1.5em;
    font-weight: normal;
    line-height: 1.4;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  .unit-options {
    position: absolute;
    right: 1em;
    bottom: 0;
    height: 40px;
    display: flex;
    align-items: center;
    margin: 5px 0;
    user-select: none;
  }

  .help-text {
    color: hsl(0deg, 0%, 51%);
    font-size: 0.8em;
    margin-left: 4px;
  }

  @media screen and (min-width: 768px) {
    .field-project,
    .field-date {
      grid-column: 1 / 2;
    }

    .field-tags,
    .field-amount {
      grid-column: 2 / -1;
    }

    .actions {
      flex-flow: row nowrap;
    }
  }
}
</style>
