<template>
  <div class="kudos">
    <div class="heading-container">
      <h2>Kudos ✨</h2>
      <div class="button-row">
        <button class="button" disabled="true">
          <font-awesome-icon icon="award" /> Send one
        </button>
        <button class="button" disabled="true">View all</button>
      </div>
    </div>
    <div class="given">
      <h3>GIVEN</h3>
      <KudosTable :kudos="given" given />
      <div>
        <VclTable v-if="loading" class="skeleton" :rows="3" :columns="3" />
        <p v-else-if="given.length === 0" class="no-entries">
          😿 No kudos given 😿
        </p>
      </div>
    </div>
    <div class="received">
      <h3>RECEIVED</h3>
      <KudosTable :kudos="received" received />
      <div>
        <VclTable v-if="loading" class="skeleton" :rows="3" :columns="3" />
        <p v-else-if="received.length === 0" class="no-entries">
          😿 No kudos received 😿
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import KudosTable from '@/components/employee-ng/KudosTable'
import { VclTable } from 'vue-content-loading'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Kudos',
  components: {
    KudosTable,
    VclTable,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapState({
      received: (state) => state.ngDashboard.kudos.received,
      given: (state) => state.ngDashboard.kudos.given,
    }),
  },
  mounted() {
    this.fetchKudos().finally(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions(['fetchKudos']),
  },
}
</script>

<style lang="scss" scoped>
.kudos {
  .heading-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .button-row {
      .button:first-child {
        margin-right: 10px;
      }
    }
  }
}
</style>
