<template>
  <div class="invoicing-per-employee table admin-vue-section">
    <h1>
      Client Invoicing per Employee in {{ monthName }}
      <LuxonMonthSelector
        v-model="month"
        :months-in-future="0"
        :initial-date="month"
        @input="refresh"
      />
    </h1>

    <h2>Active employees</h2>
    <div v-if="activeEmployees.length > 0" class="clients">
      <invoicing-employee-box
        v-for="employee in activeEmployees"
        :key="employee.id"
        :employee-data="employee"
        :month="month"
      />
    </div>
    <strong v-else>No data for selected month</strong>

    <h2>Zero billing employees</h2>
    <p>Employees with zero billing during {{ monthName }}</p>
    <div v-if="zeroBillingEmployees.length > 0" class="clients">
      <ul>
        <li v-for="employee in zeroBillingEmployees" :key="employee.id">
          {{ employee.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

import { findGetParameter } from '../../utils'
import LuxonMonthSelector from '../LuxonMonthSelector.vue'
import InvoicingEmployeeBox from './InvoicingEmployeeBox.vue'

export default {
  components: { LuxonMonthSelector, InvoicingEmployeeBox },
  props: [],

  data() {
    const dateInQuery = findGetParameter('date')
    const date = dateInQuery !== null ? DateTime.fromISO(dateInQuery) : DateTime.now()
    return {
      month: date,
      activeEmployees: [],
      zeroBillingEmployees: [],
    }
  },

  computed: {
    monthName() {
      return this.month.toFormat('MMMM, yyyy')
    },

    tableHeadings() {
      return this.activeEmployees.length > 0 ? Object.keys(this.activeEmployees[0]) : []
    },
  },

  mounted() {
    this.fetch()
  },

  methods: {
    refresh(monthSelectorValue) {
      // update the GET date param
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('date', this.month.toISODate())
      const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
      history.pushState(null, '', newRelativePathQuery)
      this.fetch()
    },

    fetch() {
      fetch(`/api/v1/invoicing/invoicing-per-employee/${this.month.toFormat('yyyy-MM')}`, {
        credentials: 'same-origin',
      }).then((response) =>
        response.json().then((data) => {
          console.log(data)
          const perEmployee = data.per_employee
          const actives = []
          const zeroes = []
          for (const employee of perEmployee) {
            if (employee.billing_sum > 0) {
              actives.push(employee)
            } else {
              zeroes.push(employee)
            }
          }
          this.activeEmployees = actives.sort((a, b) => a.name.localeCompare(b.name))
          this.zeroBillingEmployees = zeroes.sort((a, b) => a.name.localeCompare(b.name))
        })
      )
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/scss/variables';

.invoicing-per-employee {
  h2 {
    margin-top: 40px;
  }

  .boxes {
    display: flex;
    width: 100%;

    .big-box {
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  .clients,
  .zero-billing-clients {
    > * {
      margin: 10px 0;
    }
  }

  .zero-billing-clients {
    .accordion {
      header {
        background: $light-grey;
      }
    }
  }
}
</style>
