<template>
  <div v-if="error || salary" class="salary-details">
    <h2>Salary 💰</h2>

    <div v-if="error" class="error-notification">
      {{ error }}
    </div>

    <template v-if="history">
      <h3>Performance Metrics</h3>

      <table>
        <thead>
          <tr>
            <th>Month</th>
            <th>Total Score</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="monthData in history.month_wise_scores"
            :key="`month-${monthData.month}`"
          >
            <td>{{ monthData.month | formatMonth }}</td>
            <td>{{ monthData.score_all | formatScore }}</td>
          </tr>
        </tbody>
      </table>
    </template>

    <template v-if="salary">
      <h3>Salary Summary for {{ thisMonth }}</h3>

      <table>
        <tbody>
          <tr>
            <td>Average performance score</td>
            <td>{{ history.mean_score | formatScore }}</td>
          </tr>

          <tr>
            <td>Base salary</td>
            <td>
              {{ salary.salary | formatCurrency(salary.salary_currency) }}
            </td>
          </tr>

          <tr>
            <td>Expected bonus</td>
            <td>{{ salary.bonus | formatCurrency(salary.salary_currency) }}</td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>Expected gross salary</td>
            <td>
              {{ salary.gross_salary | formatCurrency(salary.salary_currency) }}
            </td>
          </tr>
        </tfoot>
      </table>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'SalaryDetails',

  filters: {
    formatCurrency(value, currency) {
      return value > 0
        ? `${Number(value).toLocaleString('en-US')} ${currency}`
        : '—'
    },

    formatMonth(month) {
      return moment(month).format('MMMM YYYY')
    },

    formatScore(score) {
      return score > 0 ? score : '—'
    },
  },

  data() {
    return {
      error: null,
      isLoading: false,
    }
  },

  computed: {
    history() {
      return this.stats?.utilization_history ?? null
    },

    salary() {
      return this.stats?.salary ?? null
    },

    stats() {
      return this.$store.getters.employeeStats ?? null
    },

    thisMonth() {
      return moment().format('MMMM')
    },
  },

  async mounted() {
    this.isLoading = true

    try {
      await this.fetchEmployeeStats()
    } catch (error) {
      console.error(`Failed fetching employee stats: ${error}`)
      this.error = error
    }

    this.isLoading = false
  },

  methods: {
    ...mapActions(['fetchEmployeeStats']),
  },
}
</script>

<style lang="scss" scoped>
@use 'sass:color';
@import 'src/assets/scss/variables';

$foreground-color: $grey;
$border-color: $light-grey;
$row-background-color: $white;
$row-background-color-alt: hsl(0deg, 0%, 98%);
$row-background-color-footer: $light-grey;
$cell-horizontal-padding: 0.5em;
$cell-vertical-padding: 1em;
$error-color: $red;

@mixin table-emphasized-font-style {
  font-weight: bold;
  text-transform: uppercase;
}

.salary-details {
  display: flex;
  flex-flow: column nowrap;
  font-size: 1em;

  h2,
  h3 {
    text-transform: uppercase;
  }

  table {
    width: calc(100% + 2 * $cell-horizontal-padding);
    align-self: center;
    color: $foreground-color;
    border-collapse: collapse;
    font-size: inherit;

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: $row-background-color;
        }

        &:nth-child(even) {
          background-color: $row-background-color-alt;
        }
      }
    }

    tfoot {
      tr {
        background-color: $row-background-color-footer;

        td {
          @include table-emphasized-font-style;
        }
      }
    }

    tr {
      td,
      th {
        padding: $cell-vertical-padding $cell-horizontal-padding;

        &:first-child {
          text-align: left;
        }

        &:last-child {
          width: 33%;
          text-align: center;
        }
      }

      th {
        @include table-emphasized-font-style;

        border-bottom: 2px solid $border-color;
      }
    }
  }

  .loader {
    display: flex;

    .spinner {
      margin: 50px auto;
    }
  }

  .error-notification {
    text-align: center;
    padding: 3em;
    color: $error-color;
    background-color: color.adjust($error-color, $lightness: 55%);
    border: 1px solid $error-color;
    border-radius: 1em;
  }
}
</style>
