<template>
  <div>
    <h2>Allocations</h2>
    <h3>Monthly allocations</h3>
    <div
      v-if="allocations && allocations.length"
      class="table client-view card"
    >
      <table class="full-width nth-coloring" style="background: none">
        <tr>
          <th class="center">id</th>
          <th class="center">Project</th>
          <th class="center">Days per month</th>
        </tr>
        <tr v-for="(allocation, index) in allocations.monthly" :key="index">
          <td class="center">
            {{ allocation.id }}
          </td>
          <td class="center">
            {{ allocation.project }}
          </td>
          <td class="center">
            <!--  -->
            {{ allocation.days_per_month }}
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <h3 style="text-align: center">No allocations available</h3>
    </div>
    <h3>Project allocations</h3>
    <div
      v-if="allocations && allocations.length"
      class="table client-view card"
    >
      <table class="full-width nth-coloring" style="background: none">
        <tr>
          <th class="center">id</th>
          <th class="center">Project</th>
          <th class="center">Days total</th>
          <th class="center">Days left</th>
        </tr>
        <tr v-for="(allocation, index) in allocations.project" :key="index">
          <td class="center">
            {{ allocation.id }}
          </td>
          <td class="center">
            {{ allocation.project }}
          </td>
          <td class="center">
            {{ allocation.days_total }}
          </td>
          <td class="center">
            {{ allocation.days_left }}
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <h3 style="text-align: center">No allocations available</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clientId: {
      type: [String, Number],
      default: '',
    },

    employeeId: {
      type: [String, Number],
      default: '',
    },

    month: {
      type: [String, Number],
      default: '',
    },
  },

  data() {
    return {
      allocations: { monthly: [], project: [] },
    }
  },

  watch: {
    month(month) {
      this.fetchData()
    },
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    fetchData() {
      const url = `/api/v1/planning/allocations/?month=${
        this.month || ''
      }&client=${this.clientId}&employee=${this.employeeId}`

      fetch(url, { credentials: 'same-origin' }).then((response) =>
        response.json().then((data) => {
          this.allocations = data.allocations
        })
      )
    },
  },
}
</script>

<style lang="scss" scoped></style>
