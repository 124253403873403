<template>
  <div class="sidebar">
    <div
      v-if="isDrawerOpen"
      v-show="isDrawerOpen"
      :style="sidebarBackdrop"
      @click="closeSidebarPanel"
    ></div>

    <transition :name="slide">
      <div v-show="isDrawerOpen" :style="sidebarPanel">
        <div class="drawer-content">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: false,
    },

    openFrom: {
      type: String,
      required: true,
      validator: (value) => ['left', 'right'].includes(value),
    },
  },

  computed: {
    slide() {
      return this.openFrom === 'right' ? 'slideRight' : 'slideLeft'
    },

    sidebarBackdrop() {
      return {
        'background-color': 'rgba(0, 0, 0, 0.3)',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: '0',
        [this.openFrom]: '0',
        cursor: 'pointer',
        'z-index': '1000',
      }
    },

    sidebarPanel() {
      return {
        'overflow-y': 'auto',
        'background-color': 'white',
        position: 'fixed',
        [this.openFrom]: '0',
        top: '0',
        height: '100vh',
        'z-index': '1000',
        width: '92%',
      }
    },

    closeDrawer() {
      return {
        float: this.openFrom === 'right' ? 'left' : 'right',
        top: '0px',
        right: '0px',
        padding: '10px 20px',
        margin: '10px',
        color: 'white',
        cursor: 'pointer',
        'font-size': '32px',
        background: 'black',
        'border-radius': '50%',
        'line-height': '40px',
        transition: 'background-color 300ms, color 100ms 200ms',
      }
    },
  },

  mounted() {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.$emit('closeDrawer')
      }
    })
  },

  methods: {
    closeSidebarPanel() {
      this.$emit('closeDrawer')
    },
  },
}
</script>

<style>
.slideRight-enter-active,
.slideRight-leave-active {
  transition: transform 0.5s ease;
}

.slideLeft-enter-active,
.slideLeft-leave-active {
  transition: transform 0.5s ease;
}

.slideRight-enter,
.slideRight-leave-to {
  transform: translate(100%);
}

.slideLeft-enter,
.slideLeft-leave-to {
  transform: translate(-100%);
}

.icon-close {
  color: black;
  margin: 30px 0 0 30px;
  height: 84px;
}

.drawer-content {
  padding: 50px;
}
</style>
